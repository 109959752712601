import React, { useMemo, createRef, useEffect, useRef } from "react";
import { ScreenProps, OrderDetail } from '../types';
import { StyleSheet, css } from "aphrodite";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonProgressBar,
} from "@ionic/react";
import { makeOrder } from "../library/functions";
import OrderSummary from "../components/OrderSummary";
import ValueItem from "../components/ValueItem";
import FooterButton from "../components/FooterButton";
import UserLocationAddressModal from "../modals/UserLocationAddress";
import CardValueInput from "../components/CardValueInput";
import TelephoneInput from "../components/TelephoneInput";
import useFirestore from '../hooks/useFirestore';
import HandoverTimeInput from "../components/HandoverTimeInput";
import useAlertToast from "../hooks/useAlertToast";
import { price as formatPrice } from "../library/format";
import PaymentGatewayModal from "../modals/PaymentGateway";
import { isOrderPending } from "../library/order";


export default function OrderRequestScreen({
  match, history, user, location, remoteConfig,
  orderRequests, updateOrderRequest,
  creditCard, updateCreditCard,
  userLocation, updateUserLocation,
  userContacts, updateUserContacts,
  orderRequestScreens, updateOrderRequestScreen,
}: ScreenProps) {
  const { params: { dealerId } } = match;
  const { state: { dealer: dealerPreview = {} } = {} } = location;
  const { [dealerId]: orderRequest = orderRequests.default } = orderRequests;
  const { [dealerId]: orderRequestScreen = orderRequestScreens.default } = orderRequestScreens;
  const { showPaymentGatewayModal, showUserLocationModal, creditCardError, error: screenError } = orderRequestScreen;

  // .. loads ..
  const [
    dealer = dealerPreview,
    loadingDealer,
    errorDealer,
  ] = useFirestore(db => db.doc(`dealers/${dealerId}`));

  const [
    order = {},
    loadingOrder = false,
    errorOrder,
  ]: [OrderDetail] = useFirestore(
    db => db.doc(`orders/${orderRequest.id}`),
    { skip: !orderRequest.id, realtime: true },
  );

  // .. alerts ...
  const errorAlertButton = { handler: () => { updateOrderRequestScreen({ error: null, creditCardError: null }, dealerId); } };
  useAlertToast({ message: screenError || errorDealer || errorOrder, color: 'warning', button: errorAlertButton });

  // .. memes ..
  const orderAlreadyClosed = useMemo(() => !orderRequest.totalPrice, [orderRequest.totalPrice]); // FIXME check more than totalPrice?
  const orderIsPending = useMemo(() => isOrderPending(order.id ? order : orderRequest), [order, orderRequest]);


  // .. charm ..

  // Automatically open Payment gateway modal when paymentUrl changed
  useEffect(() => {
    if (creditCard.paymentUrl) {
      updateOrderRequestScreen({ showPaymentGatewayModal: true }, dealerId);
    }
  }, [creditCard.paymentUrl, dealerId]); // eslint-disable-line

  // Redirect to Order Detail if payment success
  useEffect(() => {
    if (order.state === 'PAYMENT_SUCCESS' && (showPaymentGatewayModal || creditCard.id)) {
      updateOrderRequestScreen({ showPaymentGatewayModal: false }, dealerId);
      if (creditCard.remember) updateCreditCard({ id: order.payId, paymentUrl: null, remember: true });
      updateOrderRequest(orderRequests.default, dealerId);
      history.push(`/podnik/${dealerId}/objednavka/${order.id}`, { order });
    } else if (order.state === 'PAYMENT_FAILURE') {
      const message = 'Platba se nezdařila. Možná špatná platební karta?';
      updateOrderRequestScreen({ error: message, creditCardError: 'Chyba platby', showPaymentGatewayModal: false }, dealerId);
    }
  }, [order.state]); // eslint-disable-line

  // Kick the server order if is pending for long time
  useEffect(() => {
    if (!order.id || !orderIsPending) return; // work only with existing and pending order detail

    const limit = remoteConfig.paymentPendingTimeout;
    const duration = Date.now() - order.createdAt.toMillis();

    if (duration > limit) {
      // Remove credit card payment details and the ID because it cannot work for one-click-pay before first success payment proccessed
      updateCreditCard({ paymentUrl: null, id: creditCard.id === order.payId ? null : creditCard.id });
      console.log("timeout default reset");
      updateOrderRequest(orderRequests.default, dealerId);
    }
  }, [order.id]); // eslint-disable-line


  // .. whippers  ..

  const handleSubmit = () => {
    if (orderIsPending || orderAlreadyClosed) return;

    // place where the order request is fully completed and sent to the server
    (async () => {
      try {
        updateOrderRequest({ state: 'PENDING' }, dealerId);
        const { data: { orderDetail, paymentUrl } = {} } = await makeOrder({
          dealerId,
          orderRequest,
          creditCard,
          userLocation,
          userContacts,
        });

        // Update order request with details gained on server and ..
        updateOrderRequest(orderDetail, dealerId);
        updateCreditCard({ paymentUrl });
        // TODO: subscribe to order status field and a) goto OrderDetail or b) show payment error
      } catch (error) {
        updateOrderRequest({ id: null, state: null }, dealerId);
        updateOrderRequestScreen({ errors: error.details || [], error: error.message }, dealerId);
      }
    })();
  };

  const resetScreenErrors = () => {
    updateOrderRequestScreen({ errors: [], creditCardError: null, error: null }, dealerId);
  };

  const handleForgetCard = () => {
    if (creditCard.id) {
      updateCreditCard({ id: null, remember: false });
      setImmediate(resetScreenErrors);
    }
  };

  const handlePaymentModalClose = () => {
    updateCreditCard({ paymentUrl: null });
    updateOrderRequestScreen({ showPaymentGatewayModal: false }, dealerId);
    updateOrderRequest({ id: null, state: null }, dealerId);
  };


  // .. appereance ..
  const getScreenError = typeScope => {
    const error = orderRequestScreen.errors.find(
      ([type, message]) => type.startsWith(typeScope),
    );
    return error?.message;
  };

  const showFailureBar = orderRequestScreen.errors.length > 0;
  const showLoadingBar = loadingDealer || loadingOrder || (orderIsPending && !showPaymentGatewayModal);
  const disabledSubmit = orderIsPending || showLoadingBar; // pending or already created

  const pageRef = useRef();
  const handoverTimeRef = createRef();

  return (
    <IonPage className="force-tablet-like-view" ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/podnik/${dealerId}`} />
          </IonButtons>
          <IonTitle>Vaše objednávka</IonTitle>
          {showLoadingBar && <IonProgressBar type="indeterminate" />}
          {showFailureBar && <IonProgressBar color="danger" type="determinate" value="100%" />}
        </IonToolbar>
      </IonHeader>
      <IonContent className={css(styles.content)}>
        <div className={css(styles.inner)}>
          <ValueItem
            title="Kdyby něco, zavoláme."
            allowClickOnContainer={false}
            buttonTitle={null}
            error={getScreenError('userContacts.telephone')}
          >
            <TelephoneInput
              value={userContacts.telephone}
              onFocus={resetScreenErrors}
              onChange={(telephone) => {
                updateUserContacts({ telephone });
              }}
            />
          </ValueItem>
          {orderRequest.handover === 'delivery' && (
            <ValueItem
              title="Objednávku doručíme na adresu"
              value={orderRequest.address}
              onFocus={resetScreenErrors}
              onChangeRequest={() => {
                updateOrderRequestScreen({ showUserLocationModal: true }, dealerId);
              }}
              error={getScreenError('userLocation')}
            />
          )}
          {orderRequest.handover === 'pickup' && (
            <ValueItem
              title={`Adresa podniku ${dealer.title}`}
              value={dealer.address}
              buttonTitle={null}
              // allowClickOnContainer={false}
              // onChangeRequest={() => {
              // Open map?
              // }}
              button={false}
            />
          )}
          <ValueItem
            title={orderRequest.handover === 'delivery' ? 'Čas doručení' : 'Čas vyzvednutí'}
            error={getScreenError('requestedTime')}
            onChangeRequest={(event) => {
              if (event) handoverTimeRef.current.open();
              setImmediate(resetScreenErrors);
            }}
          >
            <HandoverTimeInput.forwardRef
              ref={handoverTimeRef}
              placeholder="Co nejdřív (do 45 min.)"
              value={orderRequest.requestedTime}
              onFocus={resetScreenErrors}
              onChange={(requestedTime) => {
                updateOrderRequest({ requestedTime }, dealerId);
              }}
              className="used-in-value-item"
              dealer={dealer}
            />
          </ValueItem>
          <ValueItem
            allowClickOnContainer={creditCard.id}
            title="Platební karta"
            buttonTitle={creditCard.id && !creditCard.paymentUrl ? 'Zapomenout' : null}
            buttonColor="danger"
            onChangeRequest={handleForgetCard}
            error={creditCardError}
          >
            <CardValueInput
              card={creditCard}
              updateCard={updateCreditCard}
              disabled={orderIsPending}
            />
          </ValueItem>
          <div className={css(styles.aboveTheButton)}>
            <OrderSummary order={orderRequest} className={css(styles.summary)} />
          </div>
        </div>
      </IonContent>

      <FooterButton
        visible
        title={orderAlreadyClosed ? 'Vyřízeno, zpět na podniky v okolí' : `Zaplatit kartou ${formatPrice(orderRequest.totalPrice)}`}
        onClick={handleSubmit}
        disabled={disabledSubmit}
        routerLink={orderAlreadyClosed ? '/' : undefined}
        routerDirection={orderAlreadyClosed ? 'back' : undefined}
        secondary={orderAlreadyClosed}
      />

      <PaymentGatewayModal
        open={showPaymentGatewayModal}
        payUrl={creditCard.paymentUrl}
        onClose={handlePaymentModalClose}
        presentingElement={pageRef.current}
      />

      {remoteConfig.handover?.includes('delivery') && (
        <UserLocationAddressModal
          open={showUserLocationModal}
          onClose={() => {
            updateOrderRequestScreen({ showUserLocationModal: false }, dealerId);
          }}
        />
      )}
    </IonPage>
  );
}

const styles = StyleSheet.create({
  content: { position: 'relative' },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 480px) and (min-height: 380px)': {
      height: 'calc(100% - 102px)', // the footer submit button space
    },
  },
  aboveTheButton: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  summary: {
    padding: '0 16px 16px',
    width: '100%',
    maxWidth: 480,
  },
});
