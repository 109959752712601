import { useState, useEffect } from "react";
import gmapsLoader from "../library/google-maps";


export default function useGoogleMaps() {
  const [google, setGoogle] = useState(undefined);

  useEffect(() => {
    gmapsLoader().then(setGoogle);
  }, []);

  return google;
}
