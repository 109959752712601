import * as firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/remote-config";
import "firebase/messaging";
import { isIOS } from "../components/GeoAddressLink";

const canUseMessaging = false && !isIOS() && Boolean(window.PushManager);

const firebaseConfig = {
  apiKey: "AIzaSyCcqlWi4WBXyFVyrBgvzDsmn3DD_5Hq8bY",
  authDomain: "paytogo-39949.firebaseapp.com",
  databaseURL: "https://paytogo-39949.firebaseio.com",
  projectId: "paytogo-39949",
  storageBucket: "paytogo-39949.appspot.com",
  messagingSenderId: "14188272444",
  appId: "1:14188272444:web:9cb5c7e40fef4bd9439c39",
  measurementId: "G-38YNK7TJ89",
};

export const instance = firebase.initializeApp(firebaseConfig);
export const firestore = (...pass) => instance.firestore(...pass);
export const messaging = canUseMessaging ? firebase.messaging() : { fuckApple: true };

if (canUseMessaging) {
  const vapid = 'BAb3tymclb-y3cORfl6hAJ10p_iHpUP4vU19_21_lAOkyAPKLDt1AXgk-OnhTzmNixzcdm9I6bt_n9ObCy3Jsq0';
  messaging.usePublicVapidKey(vapid); // FIXME move to config
  console.log(`Fuck Apple`, true);
}



export default firebase;
