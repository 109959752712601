import React, { useState } from "react";
import { IonToast } from "@ionic/react";

const reload = () => {
  window.location.reload();
};

export default function ServiceWorkerMessage({ registration }) {
  const [show, setShow] = useState(true);


  return (
    <IonToast
      position="bottom"
      size="small"
      color="success"
      isOpen={show}
      message="Je k dispozici nová verze aplikace 🎉"
      onDidDismiss={() => {
        setTimeout(reload, 100); // just tu be sure
      }}
      buttons={[
        {
          side: "end",
          icon: "refresh-outline",
          text: "Aktualizovat",
          handler: (registration) => {
            setShow(false);
            registration.waiting.postMessage("skipWaiting");
            setImmediate(reload);
          },
        },
      ]}
    />
  );
}
