import React from "react";
import { ScreenProps } from '../types';
import { StyleSheet, css } from "aphrodite";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonProgressBar,
} from "@ionic/react";
import useFirestore from '../hooks/useFirestore';
import FooterButton from "../components/FooterButton";
import DealerPreferences from "../components/DealerPreferences";
import DailyTurnover from "../components/DailyTurnover";
import DealerOrderModal from "../modals/DealerOrder";
import useAlertToast from "../hooks/useAlertToast";
import CallToAction from "../components/CallToAction";
import OrdersList from "../components/OrdersList";


export default function DealerProfileScreen({
  history, auth,
  dealerProfileScreen, updateDealerProfileScreen,
  dealerPreferences, updateDealerPreferences,
}: ScreenProps) {
  const { shownOrder } = dealerProfileScreen;
  const { email: userEmail } = auth?.user || {};

  // .. loads ..
  const [
    dealers = [],
    loadingDealers = true,
    errorDealers,
  ] = useFirestore(
    db => db.collection('dealers').where('managers', 'array-contains', userEmail),
    { skip: !userEmail },
  );

  // .. charm ..
  useAlertToast({ message: errorDealers });

  // .. appearance ..
  // Fancy pulsing empty boxes showing based on this ..
  const showLoading: Boolean = loadingDealers || auth.initializing;

  return (
    <IonPage className="force-tablet-like-view">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Jsem Obchodník</IonTitle>
          {showLoading && <IonProgressBar type="indeterminate" />}

          <IonButtons slot="end">
            <IonButton
              color="secondary"
              onClick={() => {
                updateDealerPreferences({ customerMode: true });
                history.push(`/profil`);
              }}
            >
              Zákazník
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={css(styles.content)}>
        <div className={css(styles.flex)}>
          <DealerPreferences
            className={css(styles.preference, styles.firstPreference)}
            preferences={dealerPreferences}
          />

          <DailyTurnover
            className={css(styles.preference)}
          />

          <OrdersList
            title="Vyřízené objednávky"
            className={css(styles.orders)}
            dealers={dealers}
            archivedOnly
            onOrderClick={(order) => {
              updateDealerProfileScreen({ shownOrder: order });
            }}
          >
            <CallToAction type="dealer-empty-orders" />
          </OrdersList>
        </div>
      </IonContent>

      <FooterButton
        visible={shownOrder == null}
        title={dealers.length > 1 ? "Zobrazit podniky v okolí" : "Zobrazit aktuální objednávky"}
        routerLink={dealers.length === 1 ? `/objednavky/${dealers[0].id}` : '/'}
        routerDirection="back"
      />

      <DealerOrderModal
        open={shownOrder !== null}
        onClose={() => { updateDealerProfileScreen({ shownOrder: null }); }}
        order={shownOrder}
      />

    </IonPage>
  );
}

const styles = StyleSheet.create({
  flex: { display: 'flex', flexDirection: 'column', minHeight: '100%', marginBottom: 0 },
  firstPreference: {
    marginTop: 16,
  },
  preference: {
    margin: '8px 0',
    padding: '16px',
    background: 'var(--ion-item-background)',
  },
  orders: {
    display: 'flex', flexDirection: 'column',
    margin: '8px 0 0',
    background: 'var(--ion-item-background)',
    flex: 1,
    paddingBottom: 102,
    zIndex: 0,
  },
});
