/**
 * Well UX handled Phone number input
 * Limitation:
 *  - use only one on the screen
 *  - Czech numbers only now
 */
import React from "react";

// TODO: make serious validation
const isValidPhoneNumber = (phone) => phone.replace(/ /g, '').substr(4).length === 9;

const chunk = (arr, size) => Array.from({
  length: Math.ceil(arr.length / size),
}, (v, i) => arr.slice(i * size, i * size + size));


export default function TelephoneInput({
  value = '',
  onChange,
  label = 'Jaké je tvoje telefonní číslo?',
  onFocus,
  onBlur,
}) {
  return (
    <div>
      {label && <label htmlFor="telephone-input">{label}</label>}
      <input
        type="tel"
        value={value}
        id="telephone-input"
        placeholder="+420"
        onFocus={(event) => {
          const telephone = event.target.value;
          if (!telephone) onChange('+420');
          if (onFocus) onFocus(event);

          // Fix cursor to the end of value
          event.target.selectionStart = telephone.length;
          event.target.selectionEnd = telephone.length;
        }}
        onBlur={(event) => {
          // Make the phone nice to read
          const telephone = event.target.value;
          if (isValidPhoneNumber(telephone)) {
            const readableTelephone = `+${chunk(telephone.substr(1).replace(/ /g, ''), 3).join(' ')}`;
            onChange(readableTelephone, true);
          }
          if (onFocus) onFocus(event);
        }}
        onChange={(event) => {
          const telephone = event.target.value;
          onChange(telephone);
        }}
      />
    </div>
  );
}
