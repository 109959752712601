import React from "react";
import { IonModal, IonButton, isPlatform, IonHeader } from "@ionic/react";
import { StyleSheet, css } from "aphrodite";
import useAlertToast from "../hooks/useAlertToast";
import ModalProgressBar from "../components/ModalProgressBar";
import AddressInput from "../components/AddressInput";



export default function CommendDealerModal({
  open = false, onClose = () => { },
  form, onChange, onSubmit,
  ...pass
}) {


  const showAlert = useAlertToast({ duration: 4200 });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (!form.name && !form.address)
        throw new Error('Alespoň jméno prosím uveďte');

      onChange({ submiting: true });
      await onSubmit(form);
      onChange({ submiting: false });

      const message = `Děkujeme za zájem!`;
      setImmediate(onClose);
      showAlert(message, { color: 'success' });
    } catch (error) {
      onChange({ error, submiting: false });
    }
  };

  const disabledSubmitButton = form.name.length < 2 || form.submiting;

  return (
    <IonModal isOpen={open} swipeToClose onDidDismiss={onClose} keyboardClose {...pass}>
      <IonHeader className={css(styles.header)}>
        <ModalProgressBar submiting={form.submiting} error={form.error} />
        <h3 className={css(styles.title)}>Doporučení podniku</h3>
      </IonHeader>

      <form onSubmit={handleSubmit}>
        <div className={css(styles.address)}>
          <p className={css(styles.label)}>Jak se podnik jmenuje?</p>
          <input
            type="text"
            value={form.name}
            onChange={({ target: { value: name } }) => {
              onChange({ name });
            }}
          />
        </div>

        <div className={css(styles.address)}>
          <p className={css(styles.label)}>Kde se podnik nachází?</p>
          <AddressInput
            value={form.address}
            onChange={(address) => {
              onChange({ address });
            }}
            placeholder="Přibližná adresa"
          />
        </div>

        <div className={css(styles.submit)}>
          <IonButton

            expand="block"
            size={(isPlatform('desktop') || isPlatform('tablet')) ? 'large' : 'default'}
            type="submit"
            disabled={disabledSubmitButton}
          >
            Odeslat doporučení
          </IonButton>
        </div>
      </form>
    </IonModal>
  );
}

const styles = StyleSheet.create({
  content: { display: 'flex', flexDirection: 'row' },
  title: { color: 'var(--ion-text-color)' },
  header: { paddingBottom: 8 },
  label: { margin: '16px 0 8px' },
  submit: { marginTop: 102 },
});
