import React from "react";
import { StyleSheet, css } from "aphrodite";
import { IonModal, IonButton, isPlatform } from "@ionic/react";
import useAlertToast from "../hooks/useAlertToast";
import ModalProgressBar from "../components/ModalProgressBar";

export default function DealerSignInModal({
  open, onClose,
  auth,
  onUpRequest,
  form, onChange, onSubmit,
  ...pass
}) {
  const showAlert = useAlertToast({ message: form.error?.message });

  const handleSubmit = (event) => {
    event.preventDefault();
    const showError = error => showAlert(error.message, { color: 'warning' }); // danger is too much
    onSubmit(form).then(onClose).catch(showError);
  };


  const handleFieldFocus = () => {
    onChange({ error: null });
  };

  return (
    <IonModal
      isOpen={open}
      swipeToClose
      keyboardClose
      onWillDismiss={() => onClose({ submit: false, reset: false })}
      {...pass}
    >
      <div className={css(styles.header)}>
        <ModalProgressBar submiting={form.submiting} error={form.error} />
        <h3 className={css(styles.title)}>Přihlášení Podniku</h3>
        <IonButton
          size="small"
          fill="outline"
          color="secondary"
          onClick={onUpRequest}
        >
          Registrace
        </IonButton>
      </div>
      <form className={css(styles.form)} onSubmit={handleSubmit} >
        <label method="post" className="ion-margin-bottom">
          <span>E-mail</span>
          <input
            name="email"
            type="email"
            value={form.email}
            onFocus={handleFieldFocus}
            onChange={({ target: { value } }) => {
              onChange({ email: value });
            }}
          />
        </label>
        <label>
          <span>Heslo</span>
          <input
            name="password"
            type="password"
            onFocus={handleFieldFocus}
            value={form.password}
            onChange={({ target: { value } }) => {
              onChange({ password: value });
            }}
          />
        </label>
        <button type="submit" className="ion-hide" aria-hidden />
      </form>

      <IonButton
        color="primary"
        size={(isPlatform('desktop') || isPlatform('tablet')) ? 'large' : 'default'}
        onClick={handleSubmit}
        expand="block"
        className={css(styles.submit)}
      >
        Přihlásit
        </IonButton>
    </IonModal>
  );
}

const styles = StyleSheet.create({
  content: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'var(--ion-text-color)',
    paddingBottom: 16,
  },
  title: { flex: 1, margin: 0, padding: 0 },
  form: { padding: "8px 0", minHeight: 240 },
  submit: { marginTop: 16 },

});
