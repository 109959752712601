import {
  useMemo, useEffect, useState,
} from "react";
import { GeoFirestore } from 'geofirestore';
import firebase from '../library/firebase';

const point = (...pass) => new firebase.firestore.GeoPoint(...pass);

/**
 * Brings data based on GEO coordinates
 *
 *  It is Firestore + http://geofirestore.com/
 *
 *  Example of option where: ['handover', 'array-contains-any', ['delivery']]
 */
export default function useGeollection(
  type = 'dealers',
  [longitude = 50.0421367, latitude = 14.5664275] = [],
  { user, where, radius = 5, skip = false }, // 5km
) {
  const [results, setResults] = useState({});

  const updateResults = (updates) => {
    setResults({
      ...results,
      [type]: {
        ...results[type], ...updates, longitude, latitude,
      },
    });
  };

  const geoQueryCriteria = useMemo(() => ({
    center: point(longitude, latitude),
    radius,
  }), [longitude, latitude, radius]);

  useEffect(() => {
    const tmp = results[type] || {}; // Not just Cache policy,
    if (!user || skip || (tmp.longitude === longitude && tmp.latitude === latitude)) return;
    updateResults({ loading: true });

    const firestore = firebase.firestore();
    const geofirestore = new GeoFirestore(firestore);

    let geocollection = geofirestore.collection('geollection');

    if (where) {
      const [left, op, right] = where;
      geocollection = geocollection.where('type', '==', type).where(`data.${left}`, op, right);
    }

    const sortByDistance = snapshot => {
      // https://github.com/geofirestore/geofirestore-js/issues/101#issuecomment-486010040
      return snapshot.docs.sort((a, b) => a.distance - b.distance);
    };

    geocollection.near(geoQueryCriteria).get().then((value) => {
      const data = value.empty ? [] : sortByDistance(value).map((doc) => ({
        id: doc.id,
        ...doc.data()?.data,
        distance: doc.distance,
      }));

      updateResults({ data, loading: false });
    }).catch((error) => {
      updateResults({ error, loading: false });
    });
  }, [user, type, where]); // eslint-disable-line

  // console.log(type, results[type]);

  return results[type] || { loading: true };
}
