// library for working with Opening Hours
// https://schema.org/openingHours

const days = ["mo", "tu", "we", "th", "fr", "sa", "su"];

// implementation taken from https://github.com/osmlab/jsopeninghours
export function parseOpeningHours(text) {
    if (!text) {
        return null;
    } else if (new Set(["24/7", "nonstop"]).has(text.toLowerCase())) {
        return { "24/7": true };
    } else {
        const result = {};
        let modifiedSomeDays = false;
        for (let k = 0; k < days.length; k++) {
            result[days[k]] = null;
        }

        const dayRegex = /^(mo|tu|we|th|fr|sa|su)-?(mo|tu|we|th|fr|sa|su)?$/;
        const timeRegex = /^\s*(\d\d:\d\d)-(\d\d:\d\d)\s*$/;
        const dayRanges = text.toLowerCase().split(/\s*;\s*/);
        let dayRange;
        while ((dayRange = dayRanges.shift())) {
            const dayTimes = dayRange.trim().split(/\s+/);
            let dayTime;
            let startDay = 0;
            let endDay = 6;
            let startTime;
            let endTime;

            while ((dayTime = dayTimes.shift())) {
                if (dayRegex.test(dayTime)) {
                    const dayMatches = dayTime.match(dayRegex);

                    if (dayMatches.length === 3) {
                        startDay = days.indexOf(dayMatches[1]);
                        if (dayMatches[2]) {
                            endDay = days.indexOf(dayMatches[2]);
                        } else {
                            endDay = startDay;
                        }
                    } else {
                        return null;
                    }
                } else if (timeRegex.test(dayTime)) {
                    const timeMatches = dayTime.match(timeRegex);

                    if (timeMatches.length === 3) {
                        startTime = timeMatches[1];
                        endTime = timeMatches[2];
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }

            for (let j = startDay; j <= endDay; j++) {
                result[days[j]] = [startTime, endTime];
                modifiedSomeDays = true;
            }

            if (!modifiedSomeDays) {
                return null;
            }
        }
        return result;
    }
}

export function formatTime(dateTime) {
    return [
        ("0" + dateTime.getHours()).slice(-2),
        ("0" + dateTime.getMinutes()).slice(-2),
    ].join(":");
}

export function getInRange(ranges, dateTime) {
    if (!ranges) {
        return null;
    } else if (ranges["24/7"]) {
        return { isOpen: true, isNonstop: true };
    } else if (ranges["seasonal"]) {
        return { isOpen: true, isSeasonal: true };
    }
    const day = days[dateTime.getDay() - 1];
    const [startTime, endTime] = ranges[day] ?? [null, null];
    if (!startTime && !endTime) {
        return { isOpen: false, startTime, endTime };
    }
    const time = formatTime(dateTime);
    return { isOpen: (startTime < time) && (time < endTime), startTime, endTime };
}

export function formatOpenHours(s, date) {
    const ranges = parseOpeningHours(s);
    const { isOpen, isNonstop, endTime } = getInRange(ranges, date) ?? {};
    if (isOpen && isNonstop) {
        return ["Otevřeno non-stop", "success"];
    } else if (isOpen) {
        return [`Otevřeno do ${endTime}`, "success"];
    } else {
        return ["Zavřeno", "medium"];
    }
}
