import React, { useCallback } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { StyleSheet, css } from "aphrodite";
import { addOutline as iconPlus, removeOutline as iconMinus } from "ionicons/icons";

export default function AmountButtons({
  label = 'Amount:',
  productTitle = '',
  value = 0,
  onChange = () => { },
  max = Infinity,
  className,
}) {
  const amountButton = useCallback((add) => ( // arg can be +1 or -1
    <IonButton
      size="small"
      color="light"
      disabled={add < 0 ? value < 1 : value > max}
      onClick={(event) => {
        event.preventDefault();
        onChange(value + add);
      }}
      className="force-button-size-by-parent"
      aria-label={add < 0 ? 'Odebrat ' + productTitle : 'Přidat ' + productTitle}
      role="button"
    >
      <IonIcon
        size="small"
        slot="icon-only"
        icon={add > 0 ? iconPlus : iconMinus}
      />
    </IonButton>
  ), [value, max, productTitle, onChange]);

  return (
    <div className={`${css(styles.container)} ${className}`}>
      <div className={css(styles.label)}>{label}</div>
      <div className={css(styles.amount)} aria-label="Množství">{`${value}ks`}</div>
      <div className={css(styles.button)}>{amountButton(-1)}</div>
      <div className={css(styles.button)}>{amountButton(+1)}</div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: { flex: 1, textTransform: 'uppercase', fontSize: 12 },
  amount: { fontSize: 12 },
  button: {
    height: 40, width: 40, marginLeft: 10,
  }, // will make horizontal space for label as well
});
