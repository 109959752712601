/*
   Imported in index,html <script src="%PUBLIC_URL%/assets/feather.min.js"></script>
*/

import { useEffect } from "react";

const fuckMe = (execution, speed = 10, duration = 600) => {
  let operation = setInterval(execution, speed);
  setTimeout(() => { clearInterval(operation); }, duration);
};

export default function useFeatherIcons() {
  const { feather: { replace: showIcons } = {} } = global || window || {};
  useEffect(() => { fuckMe(showIcons); }, [showIcons]);
  return showIcons;
}
