import { firestore } from "../library/firebase";
/*
 *
 */
export default function useFirestoreForm(formType: String, fieldsToSend = [], formState = 'NEW') {
  if (typeof formType !== 'string')
    throw new Error("First argument has to be form type (String!)");
  return ({ ...formData }) => {
    Object.keys(formData).forEach(fieldName => {
      if (fieldsToSend.includes(fieldName) === false) {
        delete formData[fieldName];
      }
    });
    Object.assign(formData, { datetime: new Date(), type: formType, state: formState });
    return firestore().collection('formulare').add(formData);
  };
}
