import firebase from "../library/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect } from "react";

// const actionCodeSettings = email => ({
//   // URL you want to redirect back to. The domain (www.example.com) for this
//   // URL must be whitelisted in the Firebase Console.
//   url: `https://app.paytogo.cz/?email=${email}`,
//   // This must be true.
//   handleCodeInApp: true,
//   iOS: {
//     bundleId: 'com.topmonks.paytogo',
//   },
//   android: {
//     packageName: 'com.topmonks.paytogo',
//     installApp: true,
//     minimumVersion: '12',
//   },
//   dynamicLinkDomain: 'paytogo.page.link',
// });



export default function useFirebaseAuth() {
  const [user, initializing, error] = useAuthState(firebase.auth());

  useEffect(() => {
    if (!initializing && !user?.uid) {
      firebase.auth().signInAnonymously();
    }
  }, [initializing, user]);

  return {
    user, initializing, error,

    signUp: async ({ email }) => {
      return firebase.auth().sendSignInLinkToEmail(email);
    },
    signIn: async ({ email, password }) => {
      return firebase.auth().signInWithEmailAndPassword(email, password);
    },
    signOut: async () => {
      return firebase.auth().signOut();
    },
  };

}
