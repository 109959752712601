

import React from "react";
import { StyleSheet, css } from "aphrodite";
import { IonModal, IonButton } from "@ionic/react";


export default function PaymentGatewayModal({
  open, onClose,
  payUrl,
  ...pass
}) {
  return (
    <IonModal
      isOpen={open}
      swipeToClose
      keyboardClose
      onWillDismiss={() => onClose({ submit: false, reset: false })}
      {...pass}
    >
      <div className={css(styles.header)}>
        <h3 className={css(styles.title)}>Platební brána</h3>
        <IonButton
          size="small"
          color="light"
          onClick={() => {
            onClose({ reset: true });
          }}
        >
          zrušit
        </IonButton>
      </div>
      <div className={css(styles.content)}>
        {payUrl && (
          <iframe
            title="Platební brána"
            className={css(styles.webview)}
            src={payUrl}
          />
        )}
      </div>
    </IonModal>
  );
}

const styles = StyleSheet.create({

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'var(--ion-text-color)',
    paddingBottom: 16,
    borderBottom: '2px solid var(--custom-input-border)',
  },
  title: { flex: 1, margin: 0, padding: 0 },

  content: {
    height: '72vh',
  },

  webview: {
    width: '100%',
    height: '100%',
    border: 0,
  },

});
