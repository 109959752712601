import React from "react";
import { IonChip, IonNote } from "@ionic/react";
import { formatOpenHours } from "../library/opening-hours";

export default function OpeningHours({ hours, chip }) {
  const [text, color] = formatOpenHours(hours, new Date());
  return (
    chip
      ? <IonChip color={color}>{text}</IonChip>
      : <IonNote color={color}>{text}</IonNote>
  );
}
