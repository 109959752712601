import useWindowSize from "./useWindowSize";

/**
 * To horizontally scrollable containers with cards, like tabs (segment) in header,
 * and a need to display only part of the last tab to show user that row is scrollable
 */
export default function useScrollRowCardSize({ coefficient = 2.5 } = {}) {
  const { width: screenWidth } = useWindowSize();

  const maxCardWidth = "320px";
  let minCardWidth = (screenWidth / coefficient).toFixed(0);
  if (minCardWidth < 148) minCardWidth = ((screenWidth / 2) * 0.88).toFixed(0);

  if (minCardWidth > maxCardWidth) minCardWidth = 280;

  minCardWidth += "px";

  return {
    minCardWidth,
    maxCardWidth,
  };
}
