import React from 'react';

const stateToText = state => {
  switch (state) {
    case "PAYMENT_SUCCESS": return ['Čeká na přijetí', 'danger'];
    case "PAYMENT_REFUND_SUCCESS": return ['Storno, refundace úspěšná'];
    case "PAYMENT_REFUND_FAILURE": return ['Storno, chyba refundace', 'danger'];
    case "ACCEPTED": return ['Přijato', 'success'];
    case "REJECTED": return ['Storno', 'warning'];
    case "DONE": return ['Vyřízeno'];
    default:
      return console.warn(`Unknown order state "${state}"`) || [];
  }
};

export default function HumanOrderState({ state, backoffice = false }) {
  const [text, color] = stateToText(state);
  return <ion-text color={color}>{text}</ion-text>;
}