import React from "react";
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  isPlatform,
} from "@ionic/react";
import { StyleSheet, css } from "aphrodite";


export default function NoUserLocationCallout({ onContinue }) {
  return (
    <div className={css(styles.container)}>

      <IonCard className={css(styles.card)}>
        <IonCardHeader>
          <IonCardTitle
            size={isPlatform('desktop') ? 'large' : 'small'}
            className={css(styles.title)}
          >
            Najdi podniky v okolí
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <p className={css(styles.description)}>
            Upřesni svou polohu a my ti ukážeme, ve kterých podnicích ve svém okolí můžeš objednávat online.
          </p>

          <IonButton
            expand="block"
            onClick={onContinue}
            className="ion-margin-top"
            size={(isPlatform('desktop') || isPlatform('tablet')) ? 'large' : 'default'}
          >
            Pokračovat
          </IonButton>
        </IonCardContent>
      </IonCard>
    </div>
  );
}


const styles = StyleSheet.create({
  container: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',

  },
  card: {
    margin: '16px auto',
    maxWidth: 480,
    paddingTop: 24,
    boxShadow: 'var(--box-shadow)',
  },
  title: { fontSize: 26, textAlign: 'center' },
  description: {
    fontSize: 18,
    textAlign: 'center',
    color: 'var(--ion-color-medium)',
    paddingBottom: 8,
  },
});
