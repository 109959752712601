
import { useState, useEffect } from "react";
import useGoogleMaps from "./useGoogleMaps";

let timer;

function suggestAccordingToUserInput(google, address, position, setSuggestions, stopFetching) {
  clearTimeout(timer);
  timer = setTimeout(() => {
    if (address === '' || stopFetching || !google) {
      return;
    }

    const autocomplete = new google.maps.places.AutocompleteService();
    const criteria = position ? {
      location: new google.maps.LatLng({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }),
      radius: 10000,
    } : {};

    autocomplete.getPlacePredictions({
      input: address,
      componentRestrictions: { country: 'CZ' },
      types: ['address'],
      ...criteria,
    }, (results, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        return;
      }
      setSuggestions(results.map((r) => ({
        place_id: r.place_id,
        description: r.description,
      })));
    });
  }, 700);
}

function suggestAccordingToDeviceLocation(google, address, position, setSuggestions) {
  if (!google || address !== '' || !position) {
    return;
  }
  const geocoder = new google.maps.Geocoder();
  const latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

  geocoder.geocode({ latLng }, (results, status) => {
    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      return;
    }

    const itToState = (item) => ({
      place_id: item.place_id,
      description: item.formatted_address,
    });

    setSuggestions(results.map(itToState));
  });
}

export default function useSuggestions({ address, position, stopFetching = false } = {}) {
  const [suggestions, setSuggestions] = useState([]);
  const google = useGoogleMaps();

  useEffect(() => {
    suggestAccordingToDeviceLocation(google, address, position, setSuggestions);
  }, [address, google, position]);

  useEffect(() => {
    suggestAccordingToUserInput(google, address, position, setSuggestions, stopFetching);
  }, [address, stopFetching, position, google]);

  return [suggestions, setSuggestions];
}
