import React, { useMemo } from 'react';
import { StyleSheet, css } from "aphrodite";
// import { IonItem, IonLabel, IonTitle, IonInfiniteScroll } from "@ionic/react";
import useFirestore from '../hooks/useFirestore';
import useAlertToast from '../hooks/useAlertToast';
import OrderItem from "./OrderItem";
import { range } from '../library/generators';



const makeCollectionQuery = ({ dealer, dealers, customerId, archivedOnly, today }) => {
  if (!customerId && !dealers.length && !dealer?.id) {
    return [null, { skip: true }];
  }

  // real updates NOT for customers and order history shown in dealer profile
  const realtime = !customerId && !archivedOnly;
  const pathMaker = db => {
    let path = db.collection('orders'); // ONLY paid orders can be shown, like only PAID orders exists

    if (dealer?.id) {
      path = path.where('dealerId', '==', dealer.id);
    } else if (dealers.length) {
      // gets orders of any dealer that user manages
      path = path.where('dealerId', 'in', dealers.map(({ id }) => id));
    } else {
      path = path.where('customerId', '==', customerId);
    }

    if (!customerId)
      path = path.where('archived', '==', archivedOnly);

    path = path.limit(100); // hot fix for leaks
    path = path.orderBy('paidAt'); // always order by paidAt (so not paid orders will be omitted)

    // For dealers show TODAY's orders ONLY
    if (!customerId && !archivedOnly && today) {
      path = path.startAt(today);
    }

    return path;
  };

  return [pathMaker, { skip: false, realtime }];
};

export default function OrdersList({
  title = 'Objednávky',
  dealers = [],
  dealer = null,
  customerId = null,
  onOrderClick = () => { },
  className,
  children: emptyElement,
  archivedOnly = false,
}) {
  // (To be able filter today's orders only later here)
  const today = useMemo(() => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }, []);

  // .. loads
  const [pathMaker, queryOptions] = makeCollectionQuery({ dealer, dealers, customerId, archivedOnly, today });
  const [
    orders = [],
    loadingOrders = true,
    errorOrders,
  ] = useFirestore(pathMaker, queryOptions);

  // .. whipers
  const handleOrderClick = order => () => {
    onOrderClick(order);
  };

  ;
  // .. appearance

  useAlertToast({ message: errorOrders });
  const showLoading: Boolean = loadingOrders;
  const placeholders = useMemo(() => Array.from(range(5)).map(i => <OrderItem.Placeholder key={i} />), []);

  return (
    <div className={`${css(styles.container)} ${className}`}>
      <h2 className={css(styles.title)}>{title}</h2>
      {orders.map(order => (
        <OrderItem
          key={order.id}
          order={order}
          onClick={handleOrderClick(order)}
        />
      ))}
      {showLoading && placeholders}
      {!showLoading && !orders.length && (emptyElement)}
    </div>
  );
}


const styles = StyleSheet.create({
  container: {
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center',
  },

  title: {
    padding: '16px',
    margin: '0 0 6px',
    fontSize: 21,
    '@media (min-width: 420px)': { fontSize: 24 },
  },
  list: {

  },
});
