
import React from "react";
import {
  IonCheckbox,
  IonLabel,
} from "@ionic/react";
import { StyleSheet, css } from "aphrodite";
import iconMastercard from '../assets/card-mastercard.png';

export default function CardValueInput({ card, updateCard, disabled }) {

  if (card.id && !card.paymentUrl) {
    return (
      <div className={css(styles.container)}>
        <img className={css(styles.cardIcon)} src={iconMastercard} alt="Uložená karta" />
        <p className={css(styles.cardMask)}>{card.mask}</p>
      </div>
    );
  }

  return (
    <div className={css(styles.container)}>
      <IonCheckbox
        mode="md"
        onIonChange={({ detail: { checked } }) => updateCard({ remember: checked })}
        className={css(styles.checkbox)}
        checked={card.remember}
        disabled={disabled}
      />
      <IonLabel className={css(styles.label)}>
        Zapamatovat kartu pro příště
      </IonLabel>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    '--size': '24px',
    margin: '4px 8px 4px 0',
  },
  label: { fontSize: 14 },
  cardIcon: { width: 32, height: 32 },
  cardMask: { margin: 8, fontSize: 16 },
});
