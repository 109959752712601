import { firestore } from "firebase";
import { History } from 'history';


export type OrderState =
  | null // first state used only on clinet
  | 'PENDING' // customer sent valid order request, order is created
  | 'PAYMENT_SUCCESS' // customer starts waiting for dealer's confirmation (reject/accept)
  | 'PAYMENT_FAILURE' // customer needs to solve payment issues, basically is trying to achive success state
  | 'PAYMENT_REFUND_SUCCESS' // follows REJECTED state
  | 'PAYMENT_REFUND_FAILURE' // had to be solved manually
  | 'ACCEPTED' // dealer confirmed the order
  | 'REJECTED' // canceled by dealer
  | 'DONE' // set by dealer

export type Handover = 'pickup' | 'delivery'

export type CreditCard = {
  id: String, // used for payId (stored payment template) received from payment provider
  remember: Boolean, // in case user wanna to store his card
  mask: String, // like XXXX XXXX XXXX XXXX
  paymentUrl: String,
}

export type MenuItem = {
  id: String,
  foreignId: String, // propably product ID fained on dealer's website
  title: String,
  description: String,
  price: Number, // as picayune (€2.20 = 200, 69Kč = 6900, ..)
  quantity: String, // like "50cm"
  additives: [String], // like alergens ["1", "5"]
  thumbnail: String, // short image url
  image: String, // an image url
}

export type MenuList = {
  title: String,
  items: [MenuItem],
  // ... (_future call: "type like meals/drugs", delete in 2040_)
}

export type MenuListPreview = {
  id: String,
  title: String,
}

export type Dealer = {
  dealerNumber: Number, // variable symbol, payment reasons
  title: String, // like a name
  address: String,
  geo: String, // The WGS84 format like 50.0406161N, 14.5588367
  handover: [Handover],
  logo: String,
  logoColor: String, // Hexa color of logo background
  menus: [MenuListPreview],
  lastOrderNumber: Number,
  testPayment: Boolean,
  openingHours: String, // schema.org/openingHours
}

export type OrderChoice = {
  menuItemId: String,
  menuListId: String,
  price: Number,
  amount: Number,
  title: String,
  quantity: String,
}

export type OrderRequest = {
  state: OrderState,
  handover: Handover,
  choices: [OrderChoice],
  requestedTime: firestore.Timestamp, // or null for ASAP, it is the time when handover come
  note: String,
  deliveryPrice: Number, // it's ([distance in km] - [free kms]) * [dealer price per km]
  orderPrice: Number, // it's choices total price
  totalPrice: Number, // picayune (cents) .. as all prices in the app
}

// ... and backend will extends it by this
export type OrderDetail = {
  id: String, // orderNumber
  ...OrderRequest,
  customerId: String,
  createdAt: firestore.Timestamp,
  state: OrderState,
  pickupTime: firestore.Timestamp,
  deliveryTime: firestore.Timestamp,
}

export type PlaceLocation = {
  address: String,
  placeId: String, // Google Maps place
  coordinates: [Number, Number], // GeoPoint
}

export type Contacts = {
  telephone: String,
  email: String,
}

export type DealerPreferences = {
  customerMode: Boolean,
  openingHours: String,
  hasClosed: Boolean,
}

// React property types

export type User = {
  uid: String,
  email: String,
  isAnonymous: Boolean,
}

export type Auth = {
  user: User,
  signIn: ({ email: String, password: String }) => void,
  signUp: ({ email: String, password: String }) => void,
}

export type AppProps = {
  onLoaded: () => void,
  orderRequests: OrderRequest,
  updateOrderRequest: (updates: OrderRequest, dealerId: String) => void,
  userLocation: PlaceLocation,
  updateUserLocation: (updates: PlaceLocation) => void,
  userContacts: Contacts,
  updateUserContacts: (updates: Contacts) => void,
  creditCard: CreditCard,
  updateCreditCard: (updates: CreditCard) => void,
  dealerPreferences: DealerPreferences,
  updateDealerPreferences: (updates: DealerPreferences) => void,
}


export type ScreenProps = {
  ...AppProps,
  auth: Auth,
  location: Location,
  history: History,
  match: Any, // FIXME: it's react router match or location match, idk
  remoteConfig: Object
}
