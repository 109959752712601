

import { EventEmitter } from 'fbemitter';
import { upperCaseFirstLetter, lastLetter } from './string';


export const updateEventName = 'update';
export const storeStateStorageName = 'magic-states-v1';

let _instance = null;

export function createMagicStore(initialStates = {}) {
  const _states = { ...initialStates };
  const emitter = new EventEmitter();
  const _updaters = {};

  const updater = (stateName) => (updates) => {
    Object.assign(_states[stateName], updates, { updatedAt: Date.now() });
    emitter.emit(updateEventName, updates);
  };

  // Generate state updaters
  for (let name of Object.keys(_states)) {
    _updaters[`update${upperCaseFirstLetter(name)}`] = updater(name);

    if (lastLetter(name) === 's' && _states[name].hasOwnProperty('default')) {
      _updaters[`update${upperCaseFirstLetter(name.slice(0, -1))}`] = (updates, key) => {
        const state = _states[name];
        if (!key) throw new Error(`Missing ${name} collection key!`);
        updater(name)({ [key]: { ...(state[key] || state.default), ...updates } });
      };
    }
  }

  const instance = {
    emitter,

    get states() {
      return { ..._states };
    },
    get updaters() {
      return { ..._updaters };
    },

    hydrate: (states) => {
      if (_states.hydratedAt) {
        throw new Error('Store can by hydrate only once');
      }
      Object.assign(_states, states, { hydratedAt: Date.now() });
      emitter.emit(updateEventName);
    },

  };

  if (!_instance) {
    _instance = instance;
  }

  return instance;
}


export function subscribeMagicStore(callback, instance = _instance, { nullth = true } = {}) {
  const subscription = instance.emitter.addListener(updateEventName, (updatedState, usedUpdates) => {
    callback(instance.states, instance.updaters, unsubscribe);
  });

  if (nullth) {
    instance.emitter.emit(updateEventName);
  }

  function unsubscribe() {
    subscription.remove();
  };

  return unsubscribe;
}


export function syncMagicStoreStateToStorage(enabled = true, instance = _instance, storageType = localStorage) {
  let trottle;

  if (storageType !== localStorage) {
    throw new Error('Unsupported storage type');
  }

  try {
    const storedStates = JSON.parse(localStorage.getItem(storeStateStorageName) || 'null');
    if (storedStates) {
      setImmediate(() => { instance.hydrate(storedStates); });
    }
  } catch (error) {
    console.warn('Magic Store Hydratation Error, removing stored version...', error);
    localStorage.removeItem(storeStateStorageName); // brutal but right
  }
  // });

  instance.emitter.addListener(updateEventName, () => {
    clearTimeout(trottle);
    trottle = setTimeout(() => {
      const serialized = JSON.stringify(instance.states);
      localStorage.setItem(storeStateStorageName, serialized);
    }, 300);
  });
}