import React from "react";
import { render } from "react-dom";
import { Plugins } from '@capacitor/core';
import { createMagicStore, subscribeMagicStore, syncMagicStoreStateToStorage } from './library/magic-store';
import Application from "./Application";
import * as serviceWorker from "./serviceWorker";
import ServiceWorkerMessage from "./components/ServiceWorkerMessage";

const { SplashScreen } = Plugins;

const handleAppLoaded = () => {
  SplashScreen.hide();
}

// Application initial states has to be defined here
createMagicStore({
  orderRequests: {
    // [dealerId]: { ... },
    default: {
      id: null,
      handover: 'pickup',
      state: null,
      requestedTime: null,
      choices: {
        // [menuItemId]: {
        //   menuListId: null,
        //   menuItemId: null,
        //   amount: 0,
        //   title: '',
        //   price: 0,
        //   quantity: '',
        // },
      },
      totalPrice: 0,
      deliveryPrice: 0,
      orderPrice: 0,
    },
  },
  creditCard: {
    id: null,
    remember: false,
    mask: 'xxxx xxxx xxxx xxxx',
    paymentUrl: null,
  },
  userLocation: {
    address: null,
    placeId: null,
    coordinates: [50.0452864, 14.4911561],
    form: {
      address: '',
      placeId: null,
    },
  },
  userContacts: {
    telephone: '',
  },
  dealerPreferences: {
    customerMode: true,
    openingHours: null,
    hasClosed: true,
  },
  homeScreen: {
    handover: 'pickup',
    showUserLocationModal: false,
    showCommendDealerModal: false,
  },
  dealerScreens: {
    // [dealerId]: { ... },
    default: {
      activeMenuListId: null,
      showSummaryModal: false,
      canBeFooterButtonVisible: true,
    },
  },
  orderRequestScreens: {
    // [dealerId]: { ... },
    default: {
      showPaymentGatewayModal: false,
      showUserLocationModal: false,
      errors: [],
      error: null,
      creditCardError: null,
    },
  },
  customerProfileScreen: {
    readyToLoad: false,
    showUserLocationModal: false,
    showDealerSignInModal: false,
    showDealerSignUpModal: false,
  },
  dealerSignForm: {
    email: '',
    password: '',
    name: '',
    address: '',
    website: '',
    telephone: '',
    userAgreedAllRequiredConditions: false,
    submiting: false,
    error: null,
  },
  dealerProfileScreen: {
    shownOrder: null,
  },
  dealerOrdersScreen: {
    shownOrder: null,
  },
});

syncMagicStoreStateToStorage(true);

const rootElement = document.getElementById("root");
subscribeMagicStore((states, updaters) => {

  render(
    <Application
      statesAndHandlers={{ ...states, ...updaters }}
      onLoaded={handleAppLoaded}
    />,
    rootElement,
  );
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  // Update prompt based on https://azdanov.js.org/blog/create-react-app-service-worker tutorial
  onUpdate: (registration) => {
    if (registration.waiting) {
      render(
        <ServiceWorkerMessage registration={registration} />,
        document.querySelector("#worker-message"),
      );
    }
  },
});

// Debug order changes logger
subscribeMagicStore((states) => {
  window.__states = states;
  setImmediate(() => {
    console.info(`%c ${JSON.stringify(states)}`, "color: #757AE9; font-weight: bold;");
  });
});

