import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { IonToast } from "@ionic/react";

const ALERT_ROOT_ELEMENT_SELECTOR = "#alert-message";

export default function useAlertToast({
  position = "top",
  color = "warning",
  duration = 0,
  button = {},
  message,
  onHide = () => { },
} = {}) {
  const options = {
    position,
    color,
    duration,
    buttons: [
      {
        side: "end",
        icon: "refresh-outline",
        text: "OK",
        handler: () => { },
        ...button,
      },
    ],
    message,
  };
  const [_alert, setAlert] = useState(options);

  // Render Ionic Toast to special root element ..
  useEffect(() => {
    ReactDOM.render(
      <IonToast
        isOpen={Boolean(_alert.message)}
        {..._alert}
        onDidDismiss={() => {
          const alert = { ...options, message: null };
          onHide(alert);
          setAlert(alert);
        }}
      />,
      document.querySelector(ALERT_ROOT_ELEMENT_SELECTOR),
    );
  }, [_alert.message]); // eslint-disable-line

  // Reflect changes passed as hook arguments ..
  useEffect(() => {
    setAlert(options);
  }, [message]); // eslint-disable-line

  // Return handler ..
  return function showAlert(message: String, options = {}) {
    if (options.button) console.warn("Not yet implemented.");
    setAlert({ ..._alert, message, ...options });
  };
}
