import React from "react";
import { StyleSheet, css } from "aphrodite";
import Illustration from "./Illustration";

const actionTypes = {
  offline: {
    illustration: 'offline',
    title: 'Ach, ty internety',
    text: 'Pravděpodobně jsi offline. Zkontroluj svoje připojení k internetu a zkus to znova.',
  },
  error: {
    illustration: 'error',
    title: 'Nefunguje to...',
    text: 'Zlaté pravidlo říká: “Dřív, než s tím praštíš, zkus to vypnout a zapnout”.',
  },
  'dealer-empty-orders': {
    illustration: 'orders-empty',
    title: 'Zatím si nikdo neobjednal',
    text: 'První objednávka se zobrazí hned, jakmile zákazník zaplatí.',
  },
  'customer-empty-orders': {
    illustration: 'orders-empty',
    title: 'Tady jednou uvidíš svoje objednávky',
    text: 'Nejdřív si ale musíš něco objednat. Mrkni na podniky ve svém okolí a pojď do toho.',
  },
  'no-dealer': {
    illustration: 'dealers-empty',
    title: 'Zatím tu nic není, ale...',
    text: 'Znáš podnik v okolí, který by tu neměl chybět? Dej nám o něm vědět a my už ho sem dostanem.',
  },
  'add-dealer': {
    illustration: 'dealers-empty',
    title: 'Chybí ti tu nějaký podnik?',
    text: 'Dej nám o něm vědět a my už ho sem dostanem.',
  },
};


type CallToActionType =
  | 'offline'
  | 'error'
  | 'dealer-empty-orders'
  | 'customer-empty-orders'
  | 'no-dealer'
  | 'add-dealer'


export default function CallToAction({ type, onContinue, className, children }: { type: CallToActionType }) {

  const { title, text, illustration } = actionTypes[type];

  return (
    <div className={`${css(styles.container)} ${className}`}>
      <div className={css(styles.inner)}>
        <Illustration type={illustration} className={css(styles.illustration)} />
        <h2 className={css(styles.title)}>{title}</h2>
        <p className={css(styles.text)}>{text}</p>
      </div>
      <div>
        {children}
      </div>
    </div>
  );
}


const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 16,
    height: '60vh', // guess? hack
    marginBottom: 24,
  },
  inner: {
    maxWidth: 420,
    textAlign: 'center',
    margin: '32px auto',
  },
  illustration: { margin: 24 },
  title: { margin: '0 0 6px', fontSize: 21, '@media (min-width: 420px)': { fontSize: 24 } },

});
