import React, { useMemo } from "react";
import {
  IonModal, IonButton, isPlatform,
} from "@ionic/react";
import { StyleSheet, css } from "aphrodite";
import OrderSummary from "../components/OrderSummary";
import ChoiceItem from "../components/ChoiceItem";
import TotalPrice from "../components/TotalPrice";


export default function OrderChoicesModal({
  open, onClose,
  order,
  ...pass
}) {
  const { handover, choices } = order;
  const items = useMemo(() => Object.values(choices), [choices]);

  return (
    <IonModal
      isOpen={open}
      swipeToClose
      keyboardClose
      onWillDismiss={() => onClose({ submit: false, reset: false })}
      {...pass}
    >
      <div className={css(styles.header)}>
        {/* Zmenil jsem z h1 na h3, diky funkci rotor je ted ve VociceOver mozne vracwet se zpet na nabidku. 🎉 */}
        <h3 className={css(styles.title)}>Objednávka</h3>
        <IonButton
          size="small"
          color="light"
          onClick={() => {
            // TODO: alert you sure? but in some cleaver way cus Ionic has only Alert component, not function to call
            onClose({ reset: true });
          }}
        >
          Smazat vše
        </IonButton>
      </div>
      <div className={css(styles.choices)}>
        {items.map((choice) => (
          <ChoiceItem
            key={choice.menuItemId}
            item={choice}
          />
        ))}
      </div>

      <OrderSummary small order={{ handover, choices }} />
      <TotalPrice order={{ choices: items }} />

      <IonButton
        color="primary"
        size={(isPlatform('desktop') || isPlatform('tablet')) ? 'large' : 'default'}
        onClick={() => {
          onClose({ submit: true });
        }}
      >
        Pokračovat k platbě
      </IonButton>
    </IonModal>
  );
}

const styles = StyleSheet.create({
  content: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'var(--ion-text-color)',
    paddingBottom: 16,
    borderBottom: '2px solid var(--custom-input-border)',
  },
  title: { flex: 1, margin: 0, padding: 0 },
  choices: {},

});
