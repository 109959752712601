import React, { useMemo } from "react";
import { StyleSheet, css } from "aphrodite";
import { calculateOrderPrice } from "../library/order-prices";
import { price as formatPrice } from "../library/format";

export default function TotalPrice({ order, value }) {
  const totalPrice = useMemo(
    () => formatPrice(value !== undefined ? value : calculateOrderPrice(order)),
    [value, order],
  );

  return (
    <p className={css(styles.totalPrice)}>
      Celková cena
      <span className="ion-float-right">{totalPrice}</span>
    </p>
  );
}

const styles = StyleSheet.create({
  totalPrice: {
    margin: "8px 0 24px 0",
    lineHeight: 1.125,
    fontWeight: 600,
    fontSize: 16,
    '@media (min-width: 480px)': {
      fontSize: 18,
    },
    borderTop: '2px solid var(--custom-input-border)',
    paddingTop: 12,
    marginTop: 0,
  },
});
