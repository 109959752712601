/**
 * Core UI component for Menu list/grid.
 *
 */

import React from "react";
import { StyleSheet, css } from "aphrodite";
import { price as formatPrice } from "../library/format";
import { OrderChoice } from '../types';

export default function ChoiceItem({ item }: { item: OrderChoice }) {

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.header)}>
        <h2 className={css(styles.title)}>{item.title}</h2>
        <div className={css(styles.price)}>{`${item.amount}x ${formatPrice(item.price)}`}</div>
      </div>
      {item.quantity && <div className={css(styles.quantity)}>{item.quantity}</div>}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    color: 'var(--ion-text-color)',
    borderBottom: '1px solid var(--ion-color-light)',
    padding: '8px 0',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: 1.125,
  },
  title: {
    flex: 1,
    margin: 0,
    fontSize: 16,
    '@media (min-width: 480px)': {
      fontSize: 18,
    },
  },
  price: {
    fontSize: 16,
    '@media (min-width: 480px)': {
      fontSize: 18,
    },
  },
  quantity: {
    color: 'var(--ion-color-medium)',
    fontSize: 12,
    '@media (min-width: 480px)': {
      fontSize: 18,
    },
  },
});
