import React, { useMemo, useCallback } from "react";
import { geoToLongLat } from "../library/geo";
import { isIOS } from "../library/platform";


/*
 * Link to native Map application (if possible)
 */
export default function GeoAddressLink({ geo, children, ...pass }) {
  const link = useMemo(
    () => `${isIOS() ? 'maps' : 'https'}://maps.google.com/maps?daddr=${geoToLongLat(geo)}&amp;ll=`,
    [geo],
  );
  const handleClick = useCallback(event => {
    event.preventDefault();
    window.open(link);
  }, [link]);

  return <a href={link} onClick={handleClick} {...pass} title="Otevřít Mapy">{children}</a>;
}
