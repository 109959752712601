import React from "react";
import { StyleSheet, css } from "aphrodite";

export default function SimpleCSSGrid({
  children,
  className = "",
  ...pass
}) {
  return (
    <div className={css(styles.container)} {...pass}>
      <div className={`${css(styles.grid)} ${className}`}>
        {children}
      </div>
    </div>
  );
}


const styles = StyleSheet.create({
  container: {},
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    gridAutoRows: "auto",
    // gridColumnGap: 16,
    // gridRowGap: 16
    // margin: 16
    '@media (min-width: 688px)': {
      margin: 8,
      gridTemplateColumns: "repeat(auto-fill, minmax(360px, 1fr))",
    },
  },
});
