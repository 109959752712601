import React, { useMemo, useRef } from "react";
import { ScreenProps } from '../types';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  isPlatform,
} from "@ionic/react";
import { StyleSheet, css } from "aphrodite";
import DealerCard from "../components/DealerCard";
import useGeollection from "../hooks/useGeollection";
import UserLocationModal from "../modals/UserLocationAddress";
import CommedDealerModal from "../modals/CommendDealer";
import ValueItem from "../components/ValueItem";
import SimpleCSSGrid from "../components/SimpleCSSGrid";
import NoUserLocationCallout from "../components/NoUserLocationCallout";
import CallToAction from "../components/CallToAction";
import { range } from "../library/generators";
import Illustration from "../components/Illustration";
import useFirestoreForm from "../hooks/useFirestoreForm";
import useAlertToast from "../hooks/useAlertToast";

/**
 * Sweeeeeet Hoooome ..
 *  It's List of Dealers if ya Customer
 *  It's List of Orders if ya the Dealer
 */
export default function HomeScreen({
  auth, remoteConfig,
  userLocation, updateUserLocation,
  homeScreen, updateHomeScreen,
  dealerSignForm, updateDealerSignForm,
  dealerPreferences,
}: ScreenProps) {

  // Get dealers near by me .. Geo + Collection = Geollection
  const { data: dealers = [], loading = true, error } = useGeollection('dealers', userLocation.coordinates, {
    user: auth.user,
    where: ['handover', 'array-contains-any', [homeScreen.handover]],
    radius: 18, // 18km around me
    skip: !homeScreen.handover,
  });

  // Propagate error to UI
  useAlertToast({ message: error });

  // Fancy pulsing empty boxes showing based on this ..
  const showLoading: Boolean = useMemo(() => loading || !auth.user, [loading, auth.user]);
  const placeholders = useMemo(() => Array.from(range(5)).map(i => <DealerCard.Placeholder key={i} />), []);

  // Boooo lean states
  const doesHaveUserAnAddress = !!(userLocation.address);
  const userIsDealer = dealerPreferences.customerMode === false && auth.user?.isAnonymous === false;

  // Collect user opinions
  const submitDealerRequestForm = useFirestoreForm('doporuceni-podniku', ['name', 'address']);
  const handleCommendDealerSubmit = (form) => submitDealerRequestForm(form).finally(() => {
    updateDealerSignForm({ name: '', address: '' });
  });

  // Modalz magic
  const pageRef = useRef();

  return (
    <IonPage className={doesHaveUserAnAddress && 'force-tablet-like-view'} ref={pageRef}>
      <IonHeader className={css(styles.header)} translucent="true">
        {doesHaveUserAnAddress && (
          <IonToolbar>
            <IonButtons slot="end">
              {userIsDealer
                ? <IonButton routerLink="/nastaveni">Nastavení</IonButton>
                : <IonButton routerLink="/profil">Můj profil</IonButton>
              }
            </IonButtons>
            <IonTitle>Podniky v okolí</IonTitle>
          </IonToolbar>
        )}
      </IonHeader>
      {doesHaveUserAnAddress && (
        <IonContent className={css(styles.content)}>
          <ValueItem
            title={homeScreen.handover === 'pickup' ? 'Kde máme hledat?' : 'Kam to chce doručit?'}
            value={userLocation.address}
            onChangeRequest={() => {
              updateHomeScreen({ showUserLocationModal: true });
            }}
          />
          <IonHeader mode="ios" collapse="condense">
            <IonToolbar className="own-margin-bottom">
              <IonTitle className={css(styles.headline)}>
                Podniky v okolí
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <SimpleCSSGrid>
            {showLoading && placeholders}
            {dealers.map((dealer, index) => (
              <DealerCard
                key={dealer?.id || index}
                dealer={dealer}
                manager={[userIsDealer, auth.user.email]}
              />
            ))}
          </SimpleCSSGrid>

          {!showLoading && (
            <CallToAction type={!dealers.length ? 'no-dealer' : 'add-dealer'}>
              <IonButton
                expand="block"
                onClick={() => { updateHomeScreen({ showCommendDealerModal: true }); }}
                className={css(styles.commendButton)}
                size={(isPlatform('desktop') || isPlatform('tablet')) ? 'large' : 'default'}
              >
                Doporučit podnik
              </IonButton>
            </CallToAction>
          )}
        </IonContent>
      )}
      {!doesHaveUserAnAddress && (
        <IonContent className={css(styles.content)}>
          <div className={css(styles.noHandover)}>
            <h1 className={css(styles.title)}>PayToGo</h1>
            <h2 className={css(styles.title, styles.subTitle)}>pro lokální podniky,<br /> v testovacím provozu</h2>

            <Illustration type="home" className={css(styles.illustration)} />
            <NoUserLocationCallout onContinue={() => { updateHomeScreen({ showUserLocationModal: true }); }} />
          </div>
        </IonContent>
      )}

      <UserLocationModal
        open={homeScreen.showUserLocationModal}
        onClose={() => { updateHomeScreen({ showUserLocationModal: false }); }}
        {...{ userLocation, updateUserLocation }}
        submitButtonTitle="Použít a najít podniky v okolí"
        presentingElement={pageRef.current}
      />
      <CommedDealerModal
        open={homeScreen.showCommendDealerModal}
        onClose={() => { updateHomeScreen({ showCommendDealerModal: false }); }}
        form={dealerSignForm}
        onChange={updateDealerSignForm}
        onSubmit={handleCommendDealerSubmit}
        presentingElement={pageRef.current}
      />
    </IonPage>
  );
}

const styles = StyleSheet.create({
  header: { background: 'var(--ion-background-color)' },
  // content: {},
  noHandover: { '--background': 'transparent', display: 'flex', height: '100%', flexDirection: 'column', marginBottom: 0 },
  headline: {
    fontSize: 24,
    lineHeight: 1,
    padding: '8px 16px', alignItems: 'flex-start', textAlign: 'left',
  },

  illustration: {
    display: 'flex', flex: 1,
    '@media (max-height: 568px)': {
      display: 'none',
    },
  },

  title: {
    fontSize: 48,
    display: 'flex',
    alignItems: 'center',
    padding: '16px 16px 0',
    textAlign: 'center',
    alignSelf: 'center',
    '@media (min-width: 780px)': {
      fontSize: 72,
    },
  },

  subTitle: {
    fontSize: 24,
    fontWeight: 'normal',
    paddig: 0,

    '@media (min-width: 780px)': {
      fontSize: 24,
    },
  },

  commendButton: {
    maxWidth: 480, // it's for desktop
    margin: '36px auto',
  },
});
