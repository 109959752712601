


export function upperCaseFirstLetter(text) {
  if (typeof text !== 'string') return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
};



export function lastLetter(text) {
  if (typeof text !== 'string') return '';
  return text.charAt(text.length - 1);
};