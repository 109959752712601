import { Plugins } from '@capacitor/core';
import { useState, useEffect, useCallback } from "react";

const { Geolocation } = Plugins;


export default function useDeviceLocation(enabledByDefault = false) {
  const [enabled, setEnabled] = useState(enabledByDefault);
  const [results, setResults] = useState({});
  const updateResults = useCallback((updates) => {
    setResults({ ...results, ...updates });
  }, [results]);

  useEffect(() => {
    if (!enabled || results.loading || results.position) return;

    updateResults({ loading: true });
    Geolocation.getCurrentPosition()
      .then((position) => {
        updateResults({ position, loading: false });
      })
      .catch((error) => {
        updateResults({ error, loading: false });
        setEnabled(false);
      });
  }, [enabled, updateResults, results.loading, results.position]);

  const handleEnable = useCallback((_enabled) => () => {
    setEnabled(_enabled);
    if (!_enabled) {
      setResults({ position: undefined });
    }
  }, []);

  return {
    ...results,
    enabled,
    enable: handleEnable(true),
    disable: handleEnable(false),
    toggleEnable: handleEnable(!enabled),
  };
}
