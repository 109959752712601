import { useEffect, useMemo, useState, useCallback } from "react";
import { instance as firebase } from '../library/firebase';

// import { parse as parseJSON } from "flatted";
const parseJSON = x => JSON.parse(x);

const settings = {
  minimumFetchIntervalMillis: .25 * 60 * 60 * 1000,
  fetchTimeoutMillis: 2 * 1000,
};

const defaults = {
  'handover': '["pickup"]',
  'paymentPendingTimeout': "60000",
};

const now = () => Number(new Date());

export default function useRemoteConfig() {
  const remoteConfig = useMemo((() => firebase.remoteConfig()), []);
  const [cache, setCache] = useState({ updatedAt: now() });

  const updateCache = useCallback((updates) => {

    // We need to manually resolve every config here
    if (updates.handover) {
      try {
        updates.handover = parseJSON(updates.handover.asString());
      } catch (error) {
        updates.handover = 'pickup';
      }
    }

    if (updates.paymentPendingTimeout) {
      updates.paymentPendingTimeout = updates.paymentPendingTimeout.asNumber();
    }

    setCache({ ...cache, ...updates, updatedAt: now() });
  }, [cache]);


  useEffect(() => {
    remoteConfig.settings = settings;
    remoteConfig.defaultConfig = defaults;
    remoteConfig.fetchAndActivate();

    const values = remoteConfig.getAll();
    try {
      updateCache(values);
    } catch (error) {
      console.warn(error);
    }
  }, []); // eslint-disable-line

  return cache;
}