import React from 'react';
import { StyleSheet, css } from "aphrodite";
import {
  IonItem,
  IonLabel,
  IonButton,
  isPlatform,
} from "@ionic/react";

export default function ValueItem({
  title,
  value,
  onChangeRequest = () => { },
  children: content,
  buttonTitle = 'změnit',
  buttonColor = 'primary',
  allowClickOnContainer = true,
  className,
  error,
  ...pass // now passing button={false}
}) {
  const handleContainerClick = () => {
    if (allowClickOnContainer) {
      onChangeRequest();
    }
  };

  return (
    <div className={`${css(styles.container, error && styles.hasError)} ${className} value-item-container`}>
      <IonItem
        button={allowClickOnContainer}
        detail={false}
        onClick={handleContainerClick}
        lines="none"
        {...pass}
      >
        {content && (
          <div className={css(styles.content)}>
            <h2 className={css(styles.title)}>{title}</h2>
            <div>{content}</div>

            <IonLabel color="danger" className={css(styles.error)} >
              {error}
            </IonLabel>
          </div>
        )}
        {!content && (
          <IonLabel>
            <h2 className={css(styles.title)}>{title}</h2>
            <p className={css(styles.value)}>{value}</p>
            <IonLabel color="danger" className={css(styles.error)} >
              {error}
            </IonLabel>
          </IonLabel>
        )}

        {buttonTitle !== null && (
          <IonButton
            slot="end"
            fill={isPlatform('desktop') ? "clear" : "clear"}
            size={isPlatform('desktop') ? 'default' : 'small'}
            color={buttonColor}
            onClick={onChangeRequest}
            className={css(styles.button)}
          >
            {buttonTitle}
          </IonButton>
        )}

      </IonItem>
    </div>);
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    margin: '0 0 8px',
    '@media (min-width: 480px)': {
      margin: '0 0 16px',
    },
    ':first-child': {
      marginTop: 16,
    },
    // borderBottom: '4px var(--ion-color-danger) solid',
    position: 'relative',
  },
  content: { margin: '10px 6px 10px 2px' },
  title: {
    margin: 0,
    marginBottom: 6,
    fontSize: 18,
    lineheight: '21px',
    fontWeight: 400,
    // color: 'var(--ion-color-danger)',
  },
  value: { fontSize: 16, color: 'var(--ion-color-medium)' },
  button: { position: 'relative', right: -8 }, // easy fix to
  //
  error: {
    fontSize: 12,
    marginTop: 8,
  },
});
