import React, { useMemo, useEffect, useRef } from "react";
import { ScreenProps } from '../types';
import { StyleSheet, css } from "aphrodite";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  IonProgressBar,
} from "@ionic/react";
import useScrollRowCardSize from "../hooks/useScrollRowCardSize";
import useFirestore from '../hooks/useFirestore';
import MenuItem from "../components/MenuItem";
import SimpleCSSGrid from "../components/SimpleCSSGrid";
import FooterButton from "../components/FooterButton";
import OrderChoicesModal from "../modals/OrderChoices";
import { range } from "../library/generators";
import useAlertToast from "../hooks/useAlertToast";
import { calculateOrderPrice, makeOrderPrices } from "../library/order-prices";
import GeoAddressLink from "../components/GeoAddressLink";


/**
 * Lets make some deal! Just pick what you woontv
 *
 *  Handled scenarios:
 *   1. transiton from the Home screen
 *   2. direct screen visit (like refresh)
 *
 *  Data flow
 *   1. preview from previous screen (dealer basics)
 *   2. detail of dealer (if the preview not exists)
 *   2. detail of **each** menu list
 */
export default function DealerScreen({
  match, auth, location, history,
  orderRequests, updateOrderRequest,
  dealerScreens, updateDealerScreen,
  userLocation,
}: ScreenProps) {
  const { params: { dealerId } } = match;
  const { state: { dealer: dealerPreview = {} } = {} } = location;
  const { [dealerId]: dealerScreen = dealerScreens.default } = dealerScreens;
  const { [dealerId]: orderRequest = orderRequests.default } = orderRequests;
  const { activeMenuListId, showSummaryModal, canBeFooterButtonVisible } = dealerScreen;

  // .. loads ..
  const [
    dealer = dealerPreview,
    loadingDealer,
    errorDealer,
  ] = useFirestore(db => db.doc(`dealers/${dealerId}`));

  const [
    viewedMenuItems = [],
    loadingMenuItems = true,
    errorMenuItems,
  ] = useFirestore(
    db => db.collection(`dealers/${dealerId}/menus/${activeMenuListId}/items`),
    { skip: !activeMenuListId },
  );


  // .. charm  ..

  // Use first menu list as selected by default
  useEffect(() => {
    if (!activeMenuListId) {
      const [{ id: firstDealerMenuListId } = {}] = dealer.menus || [];
      updateDealerScreen({ activeMenuListId: firstDealerMenuListId }, dealerId);
    }
  }, [dealerId, activeMenuListId, dealer.menus?.length]) // eslint-disable-line


  // .. whippers ..

  const changeChoice = ({ id: menuItemId, title, price, quantity } = {}, amount) => {
    const choice = orderRequest.choices[menuItemId];
    const menuListId = activeMenuListId;

    const choices = {
      ...orderRequest.choices,
      [menuItemId]: { ...choice, menuItemId, menuListId, title, quantity, price, amount },
    };
    // we're using map for holding choices and `updated` is local copy,
    if (!amount) delete choices[menuItemId]; // so it's save to this.

    updateOrderRequest({ choices }, dealerId);
  };

  const handleSummaryModalClose = ({ submit, reset } = {}) => {
    if (submit) {
      updateOrderRequest(makeOrderPrices(orderRequest, userLocation), dealerId);
      history.push(`/podnik/${dealerId}/objednavka`, { dealer });
      updateDealerScreen({ canBeFooterButtonVisible: false, showSummaryModal: false }, dealerId);
    } else if (reset) {
      updateOrderRequest({ choices: {} }, dealerId);
      updateDealerScreen({ showSummaryModal: false }, dealerId);
    } else {
      updateDealerScreen({ canBeFooterButtonVisible: true, showSummaryModal: false }, dealerId);
    }
  };

  // .. appearance ..
  useAlertToast({ message: errorDealer || errorMenuItems });

  const showDealerLoading = useMemo(
    () => !auth.user || loadingDealer,
    [auth.user, loadingDealer],
  );
  const showMenuItemsLoading = useMemo(
    () => showDealerLoading || loadingMenuItems,
    [showDealerLoading, loadingMenuItems],
  );

  const { minCardWidth, maxCardWidth } = useScrollRowCardSize();
  const placeholders = useMemo(() => Array.from(range(25)).map(i => <MenuItem.Placeholder key={i} />), []);

  const totalPrice = useMemo(() => calculateOrderPrice(orderRequest), [orderRequest]);
  const isFooterButtonVisible = canBeFooterButtonVisible && totalPrice > 0;

  const pageRef = useRef();
  const callableTelephone = dealer.telephone?.replace(/ /gi, '');

  return (
    <IonPage className="force-tablet-like-view" ref={pageRef}>
      <IonHeader className={css(styles.header)} translucent="true">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{dealer.menus?.find(({ id }) => activeMenuListId === id)?.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/profil">Můj profil</IonButton>
          </IonButtons>
          {showDealerLoading && <IonProgressBar type="indeterminate" />}
        </IonToolbar>
      </IonHeader>

      <IonContent className={css(styles.content)}>
        <IonHeader mode="ios" collapse="condense">
          <IonToolbar>
            <IonTitle size="small">
              <h1 className={css(styles.title)}>{dealer.title}</h1>
              <p className={css(styles.address)}>
                <GeoAddressLink
                  className={css(styles.link)}
                  geo={dealer.geo}
                >
                  {dealer.address}
                </GeoAddressLink>
                <a
                  className={css(styles.link)}
                  href={`tel:${callableTelephone}`}
                  title={`Zavolat ${dealer.name}`}
                >
                  {dealer.telephone}
                </a>
              </p>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        {dealer.menus?.length === 1 && <IonHeader className={css(styles.noMenuHeader)}><h2 >{dealer.menus[0].title}</h2></IonHeader>}
        {dealer.menus?.length > 1 && (
          <IonSegment
            scrollable
            value={activeMenuListId}
            mode="md"
            className={css(styles.lists)}
            onIonChange={({ target: { value } }) => {
              updateDealerScreen({ activeMenuListId: value }, dealerId);
            }}
          >
            {(dealer.menus || []).map(({ id, title: label }, index) => (
              <IonSegmentButton
                key={id || index}
                style={{
                  minWidth: minCardWidth,
                  maxWidth: maxCardWidth,
                }}
                className={css(styles.menuButton)}
                value={id}
                role="button"
                aria-label={`Nabídka ${label}`}
              >
                {label}
              </IonSegmentButton>
            ))}
          </IonSegment>
        )}
        <SimpleCSSGrid className={css(styles.grid)}>
          {viewedMenuItems.map((item) => {
            const menuItemChoice = orderRequest.choices[item.id] || {};
            return (
              <MenuItem
                key={item.id}
                item={item}
                amount={menuItemChoice.amount}
                onAmountChange={(amount) => {
                  changeChoice(item, amount);
                }}
              />
            );
          })}
          {showMenuItemsLoading && placeholders}
        </SimpleCSSGrid>
      </IonContent>

      <FooterButton
        visible={isFooterButtonVisible}
        title="Objednávka" // Zavazit kvuli zrakacum: Objednávka, 3ks za 523Kč (pro kontrolu)
        onClick={() => {
          updateDealerScreen({ showSummaryModal: true }, dealerId);
        }}
      >
        Pokračovat k platbě
      </FooterButton>

      <OrderChoicesModal
        open={showSummaryModal}
        onClose={handleSummaryModalClose}
        order={orderRequest}
        presentingElement={pageRef.current}
      />
    </IonPage>
  );
}

const styles = StyleSheet.create({
  header: { background: 'var(--ion-background-color)' },

  title: {
    margin: 0,
    padding: 0,
    fontSize: 26,
    lineHeight: 1.4,

    alignItems: 'flex-start', textAlign: 'left',
  },
  address: {
    textAlign: 'left',
    color: 'var(--ion-color-medium)',
  },
  link: { textDecoration: 'none', color: 'var(--ion-color-medium)' },
  content: {},
  noMenuHeader: { background: "var(--ion-item-background)", padding: '4px 16px' },
  grid: {
    marginTop: 8, // to unstick of header tabs
    '@media (min-width: 420px)': {
      padding: 6,
    },
  },
  contentHeaderToolbar: {},
  lists: { "--background": "var(--ion-toolbar-background)" },
});
