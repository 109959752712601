import React from "react";
import { ScreenProps } from '../types';
import { StyleSheet, css } from "aphrodite";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonBackButton,
  IonProgressBar,
} from "@ionic/react";
import useFirestore from '../hooks/useFirestore';
import useAlertToast from "../hooks/useAlertToast";
import DealerOrderModal from "../modals//DealerOrder";
import OrdersList from "../components/OrdersList";
import CallToAction from "../components/CallToAction";
/**
 * Main Dealer's screen where can menage all today's orders of his
 */
export default function DealerOrdersScreen({
  auth, remoteConfig, match, location,
  dealerOrdersScreen, updateDealerOrdersScreen,
}: ScreenProps) {
  const { params: { dealerId } } = match;
  const { state: { dealer: dealerPreview = {} } = {} } = location;
  const { shownOrder } = dealerOrdersScreen;

  // .. loads ..
  const [
    dealer = dealerPreview,
    loadingDealer,
    errorDealer,
  ] = useFirestore(db => db.doc(`dealers/${dealerId}`));

  // .. appearance ..
  useAlertToast({ message: errorDealer });
  const showLoading = loadingDealer || false;

  return (
    <IonPage className="force-tablet-like-view">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>TopMonks Pizza</IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/nastaveni">Nastavení</IonButton>
          </IonButtons>
        </IonToolbar>
        {showLoading && <IonProgressBar type="indeterminate" />}
      </IonHeader>
      <IonContent>
        <OrdersList
          title="Aktuální objednávky"
          className={css(styles.orders)}
          dealer={dealer}
          onOrderClick={(order) => {
            updateDealerOrdersScreen({ shownOrder: order });
          }}
        >
          <CallToAction type="dealer-empty-orders" />
        </OrdersList>
      </IonContent>

      <DealerOrderModal
        open={shownOrder !== null}
        onClose={() => {
          updateDealerOrdersScreen({ shownOrder: null });
        }}
        order={shownOrder}
      />
    </IonPage>
  );
}

const styles = StyleSheet.create({
  orders: {},
});
