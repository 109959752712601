import React, { createElement, useEffect } from "react";
import { AppProps, ScreenProps } from './types';
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "./theme"; // Module full of CSS imports
import useFirebaseAuth from "./hooks/useFirebaseAuth";
import useRemoteConfig from "./hooks/useRemoteConfig";

import HomeScreen from "./screens/Home";
import DealerScreen from "./screens/Dealer";
import CustomerProfileScreen from "./screens/CustomerProfile";
import OrderRequestScreen from "./screens/OrderRequest";
import OrderDetailScreen from "./screens/OrderDetail";
import DealerOrdersScreen from "./screens/DealerOrders";
import DealerProfileScreen from "./screens/DealerProfile";


export default function Application({ statesAndHandlers, onLoaded }: AppProps) {
  const remoteConfig = useRemoteConfig();
  const auth = useFirebaseAuth();

  useEffect(onLoaded, []);

  const extraScreenProps: ScreenProps = { auth, remoteConfig, ...statesAndHandlers };
  const route = (component, path: string, exact: boolean) => (
    <Route
      {...{ path, exact }}
      key={path}
      render={(pass) => {
        return createElement(component, { ...pass, ...extraScreenProps });
      }}
    />
  );

  // useEffect(() => { let u = user; debugger; }, [user?.email]); // eslint-disable-line

  return (
    <IonApp>
      <IonReactRouter>
        <div>
          <IonRouterOutlet>
            {route(HomeScreen, "/", true)}
            {route(DealerScreen, "/podnik/:dealerId", true)}
            {route(OrderRequestScreen, "/podnik/:dealerId/objednavka", true)}
            {route(OrderDetailScreen, "/podnik/:dealerId/objednavka/:orderId", true)}
            {route(OrderDetailScreen, "/objednavka/:orderId", true)}
            {route(CustomerProfileScreen, "/profil", true)}

            {route(DealerOrdersScreen, "/objednavky/:dealerId", true)}
            {route(DealerProfileScreen, "/nastaveni", true)}
            <Redirect key="gohome" to="/" />
          </IonRouterOutlet>
        </div>
      </IonReactRouter>
    </IonApp>
  );
}
