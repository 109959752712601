import { OrderRequest, OrderChoice, PlaceLocation } from '../types';

/**
 *
 */
export const calculateOrderPrice = ({ choices }: { choices: [OrderChoice] }) => {
  if (!choices) return 0;

  let total = 0;
  const defaultChoice = { price: 0, amount: 0 };
  const values = Array.isArray(choices) ? choices : Object.values(choices);

  for (let i = 0; i < values.length; i++) {
    const { amount, price } = values[i] || defaultChoice;
    total += amount * parseInt(price, 10); // to be sure ;)
  }

  return total;
};


export const calculateDeliveryPrice = (order: OrderRequest, place: PlaceLocation) => {
  if (order.handover === 'pickup') return 0;
  console.warn('calculateDeliveryPrice not yet fully implemented.', { place, order });
  return 0;
};


export const makeOrderPrices = (order: OrderRequest, place: PlaceLocation) => {
  const orderPrice = calculateOrderPrice(order);
  const deliveryPrice = calculateDeliveryPrice(order, place);

  return {
    orderPrice,
    deliveryPrice,
    totalPrice: orderPrice + deliveryPrice,
  };
};