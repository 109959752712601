import React from "react";
import * as format from '../library/format';
import { StyleSheet, css } from "aphrodite";
import useFeatherIcons from "../hooks/useFeatherIcons";
import useRemoteConfig from "../hooks/useRemoteConfig";


export default function OrderSummary({
  order = {},
  small,
  className,
  ...pass
}) {
  const { handover, requestedTime, orderPrice, deliveryPrice } = order;
  const remoteConfig = useRemoteConfig();
  useFeatherIcons();

  const canUseDeliveryFeature = remoteConfig.handover?.includes('delivery');
  // This component is usefull to see only if time and/or delivery is supported
  if (requestedTime === undefined && !canUseDeliveryFeature) { return <div />; }

  return (
    <div className={`${css(styles.container)} ${className}`} {...pass}>
      <ul className={css(styles.list)}>
        <li className={css(styles.row)}>
          <i data-feather="shopping-bag" className="orderIcon"></i>
          <div className={css(styles.label)}>
            <span>Objednávka</span>
          </div>
          <div className="ion-text-right">
            <span>{format.price(orderPrice)}</span>
          </div>
        </li>
        {canUseDeliveryFeature && (
          <li className={css(styles.row)}>
            <i data-feather="truck" className="orderIcon"></i>
            <div className={css(styles.label)}>
              <span>Doprava</span>
            </div>
            <div className="ion-text-right">
              <span>
                {!handover && deliveryPrice !== undefined && `${deliveryPrice} Kč`}
                {handover === 'delivery' && `Doručení kurýrem / ${deliveryPrice} Kč`}
                {handover === 'pickup' && 'Osobní odběr / 0 Kč'}
              </span>
            </div>
          </li>
        )}
        {(requestedTime || requestedTime === null) && (
          <li className={css(styles.row)}>
            <i data-feather="clock" className="orderIcon"></i>
            <div className={css(styles.label)}>
              <span>Čas převzetí</span>
            </div>
            <div className="ion-text-right">
              <span>{requestedTime === null ? 'Co nejdřív / do 45 min' : `Dnes ${format.time(new Date(requestedTime))}`}</span>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}


const styles = StyleSheet.create({
  container: {
    fontSize: 14,
    lineHeight: 1.5,
    color: 'var(--ion-text-color)',
    marginBottom: 8,
  },
  list: { padding: 0, margin: 0 },
  row: {
    padding: '4px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    flex: 1,
  },
});
