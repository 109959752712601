import React from "react";
import { ScreenProps } from '../types';
import { StyleSheet, css } from "aphrodite";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  // IonTitle,
  IonToolbar,
  IonProgressBar,
  useIonViewDidEnter,
  IonSkeletonText,
} from "@ionic/react";
import OrderSummary from "../components/OrderSummary";
import Illustration from "../components/Illustration";
import ChoiceItem from "../components/ChoiceItem";
import TotalPrice from "../components/TotalPrice";
import FooterButton from "../components/FooterButton";
import useFirestore from '../hooks/useFirestore';
import useAlertToast from "../hooks/useAlertToast";
import { messaging } from "../library/firebase";
import useMessaging from "../hooks/useMessaging";



export default function OrderDetailScreen({
  match, history, location, auth,
}: ScreenProps) {
  const { state: routerState = {} } = location;
  const { params: { orderId, dealerId } } = match; // dealerId indicates we goes here on new order make
  const { order: orderPreview = {} } = routerState;


  // .. loads ..
  const [
    order = orderPreview,
    loadingOrder = orderPreview.id === orderId ? false : true,
    errorOrder,
  ] = useFirestore(db => db.doc(`/orders/${orderId}`), { skip: orderPreview.id === orderId });

  // .. charm ..
  // const showAlert =
  useAlertToast({ message: errorOrder });

  // const updateOrderMeessagingToken = useMessaging(order, { skip: !order.customerId });
  // useIonViewDidEnter(() => {
  //   // messaging.getToken().then(updateOrderMeessagingToken).catch(showAlert);
  // });


  // .. appearance ..
  const showLoadingIndicators = loadingOrder || false;


  return (
    <IonPage className="force-tablet-like-view">
      <IonHeader className={css(styles.header)}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={dealerId ? '/' : '/profil'} />
          </IonButtons>
          {/* <IonTitle>Order Detail</IonTitle> */}
          {showLoadingIndicators && <IonProgressBar type="indeterminate" />}
        </IonToolbar>
      </IonHeader>
      <IonContent className={css(styles.content)}>
        <div style={{ minHeight: 160 }}>
          {order.handover === 'pickup' && <Illustration type="pickup" />}
          {order.handover === 'delivery' && <Illustration type="delivery" />}
          {showLoadingIndicators && <IonSkeletonText animated style={{ height: 148, maxWidth: 220, margin: '16px auto' }} />}
        </div>

        {/* <IonSkeletonText className={css(styles.image)} /> */}

        <div className="ion-padding">
          <h3 className={css(styles.title)}>Objednávku jsme přijali</h3>
          <p className="ion-no-margin ion-margin-bottom ion-text-center">Číslo tvojí objednávky je <strong>#{orderId}</strong></p>
        </div>

        {order.choices && <OrderSummary className={css(styles.summary)} order={order} />}

        <div className={css(styles.choices)}>
          <h4 className="ion-no-margin ion-margin-bottom">Obsah objednávky</h4>
          <div>
            {(Object.values(order.choices || {})).map((choice, index) => (
              <ChoiceItem
                key={choice.menuItemId}
                item={choice}
              />
            ))}
          </div>

          <TotalPrice value={order.totalPrice || 0} />
        </div>
      </IonContent>

      <FooterButton
        visible
        secondary
        title={dealerId ? "Zobrazit profil" : "Zpět na profil"}
        // routerLink="/"
        // routerDirection="back"
        onClick={() => {
          if (dealerId) {
            // TODO Go to profile and somehow reset the router stack
            history.push('/profil');
          } else {
            history.goBack('/profil');
          }
        }}
      />
    </IonPage>
  );
}

const styles = StyleSheet.create({
  header: {},
  content: { '--background': 'var(--ion-item-background)' },
  title: {
    textAlign: 'center',
    fontSize: 26,
    fontWeight: 500,
    // marginBottom: 0,
  },
  summary: {
    padding: 16,
    maxWidth: 480,
    margin: '16px auto',
    background: 'var(--ion-background-color)',
    '@media (min-width: 481px)': { borderRadius: 4 },
  },
  choices: {
    padding: 16,
    maxWidth: 480,
    margin: 'auto',
    background: 'var(--ion-item-background)',
    paddingBottom: 102,
  },
});
