/* eslint-disable react-hooks/rules-of-hooks */
import { firestore } from "../library/firebase";

import {
  useDocumentDataOnce,
  useDocumentData,
  useCollectionDataOnce,
  useCollectionData,
} from 'react-firebase-hooks/firestore';

const hooks = {
  false: {
    false: useCollectionDataOnce,
    true: useCollectionData,
  },
  true: {
    false: useDocumentDataOnce,
    true: useDocumentData,
  },
};

const isDocumentPath = path => Boolean(path) && path.split('/').length % 2 === 0;


/*
 *
 */
export default function useFirestore(
  makeQuery: (db: firebase.firestore.Firestore) => firebase.firestore.Query,
  { skip = false, realtime = false } = {},
): [Object, Boolean, Error, firebase.firestore.DocumentReference] {
  const query = skip ? undefined : makeQuery(firestore());
  const [data, loading, error] = hooks[skip || isDocumentPath(query?.path)][realtime](query, { idField: 'id' });
  // console.log([data, loading, error, query]);
  return [data, loading, error, query];
}


