import React from "react";
import {
  IonModal,
  IonButton,
  isPlatform,
  IonCheckbox,
} from "@ionic/react";
import { StyleSheet, css } from "aphrodite";
import TelephoneInput from "../components/TelephoneInput";
import AddressInput from "../components/AddressInput";
import useDeviceLocation from "../hooks/useDeviceLocation";
import useAlertToast from "../hooks/useAlertToast";
import ModalProgressBar from "../components/ModalProgressBar";



// const YES_I_AGREE = 'yes';
// const STEP_ONE = 1;
// const STEP_TWO = 2;


export default function DealerSignUpModal({
  open, onClose,
  form, onChange, onSubmit,
  onInRequest,
  ...pass
}) {
  const deviceLocation = useDeviceLocation();
  const showAlert = useAlertToast({ message: form.error?.message });

  const link = (url, text) => <a href={url} title="Odkaz na web" target="_blank" rel="noopener noreferrer">{text}</a>;
  const disabledSubmitButton = !form.userAgreedAllRequiredConditions;


  const handleSubmit = (event) => {
    event.preventDefault();

    (async () => {
      try {
        if (!form.name && !form.address)
          throw new Error('Alespoň jméno prosím uveďte');

        await onSubmit(form);

        const message = `Děkujeme za zájem! Ozveme se.`;
        setImmediate(onClose);
        showAlert(message, { color: 'success' });
      } catch (error) {
        showAlert(error.message, { color: 'warning' }); // danger is too much
      }
    })();
  };

  const handleFieldFocus = () => {
    onChange({ error: null });
  };

  return (
    <IonModal
      isOpen={open}
      swipeToClose
      keyboardClose
      onWillDismiss={() => onClose({ submit: false, reset: false })}
      {...pass}
    >
      <div className={css(styles.header)}>
        <ModalProgressBar submiting={form.submiting} error={form.error} />
        <h3 className={css(styles.title)}>Registrace Podniku</h3>
        <IonButton
          size="small"
          fill="outline"
          color="secondary"
          onClick={onInRequest}
        >
          Už mám účet
        </IonButton>
      </div>
      <form className={css(styles.form)} onSubmit={handleSubmit} noValidate>
        <label>
          <span>Název podniku</span>
          <input
            name="name"
            type="text"
            value={form.name}
            onFocus={handleFieldFocus}
            onChange={({ target: { value } }) => {
              onChange({ name: value });
            }}
          />
        </label>

        <label>
          <span>Adresa podniku</span>
          <AddressInput
            value={form.address}
            onChange={(value, placeId) => {
              onChange({ address: value, placeId });
            }}
            locationPosition={deviceLocation.position}
            placeholder="Kde se podnik nachází?"
            justStreet={false}
            onFocus={handleFieldFocus}

          />
        </label>

        <label>
          <span>Webové stránky</span>
          <input
            name="website"
            type="url"
            value={form.website}
            onFocus={handleFieldFocus}
            onChange={({ target: { value } }) => {
              onChange({ website: value });
            }}
          />
        </label>

        <label>
          <span>Telefonní číslo</span>
          <TelephoneInput
            name="telephone"
            label={null}
            value={form.telephone}
            onFocus={handleFieldFocus}
            onChange={(value) => {
              onChange({ telephone: value });
            }}
          />
        </label>

        <label>
          <span>E-mailová adresa</span>
          <input
            name="email"
            type="email"
            value={form.email}
            onFocus={handleFieldFocus}
            onChange={({ target: { value } }) => {
              onChange({ email: value });
            }}
          />
        </label>

        {/* <label>
          <span>Heslo (dobrovolné)</span>
          <input
            name="password"
            type="password"
            value={password}
            onFocus={handleFieldFocus}
            onChange={({ target: { value } }) => {
              onChange({ password: value });
            }}
          />
        </label> */}

        <label className={css(styles.conditions)}>
          <IonCheckbox
            mode="md"
            onIonChange={({ detail: { checked } }) => {
              onChange({ userAgreedAllRequiredConditions: checked });
            }}
            className={css(styles.checkbox)}
            checked={form.userAgreedAllRequiredConditions}
          />
          <p>Před registrací si prosím prečtěte {link('https://paytogo.cz/obchodni-podminky.html', 'Obchodní podmínky')} a {link('https://paytogo.cz/podminky-uziti.html', 'Podmínky užití')}.</p>
        </label>

        <button type="submit" className="ion-hide" aria-hidden />
      </form>

      <IonButton
        color="primary"
        size={(isPlatform('desktop') || isPlatform('tablet')) ? 'large' : 'default'}
        disabled={disabledSubmitButton}
        type="submit"
        expand="block"
      >
        Zaslat žádost o registraci
        </IonButton>
    </IonModal>
  );
}

const styles = StyleSheet.create({
  content: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'var(--ion-text-color)',
    paddingBottom: 16,
  },
  title: { flex: 1, margin: 0, padding: 0 },
  form: { minHeight: 240 },


  conditions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8 0',
    borderTop: '1px solid var(--ion-color-light)',  // IDK #C8C7CC is not in variables.css
    borderBottom: '2px solid var(--ion-color-light)', // IDK #C8C7CC is not in variables.css
    fontSize: 14,
    margin: '16px 0',
    '@media (min-width: 375px)': { margin: '32px 0' },
  },

  checkbox: {
    minWidth: 24,
    minHeight: 24,
    margin: 6,
    marginRight: 16,
  },
});
