import React from "react";
import { css, StyleSheet } from "aphrodite";
import { IonProgressBar } from "@ionic/react";

export default function ModalProgressBar({ submiting, error, value = 100 }) {
  const doNotShow = !submiting && !error;

  return doNotShow ? null : (
    <IonProgressBar
      className={css(styles.bar)}
      color={error ? 'danger' : undefined}
      type={error ? 'determinate' : 'indeterminate'}
      value={`${value}%`}
    />
  );
}

const styles = StyleSheet.create({
  bar: {
    position: 'absolute',
    top: -16,
    left: -16,
    right: -16,
    width: 'auto',
  },
});