import React from 'react';
import { IonItem, IonSkeletonText, IonLabel } from '@ionic/react';


OrderItem.Placeholder = () => (
  <IonItem>
    <IonSkeletonText animated style={{ width: '40%', height: 16 }} />
    {/* <div className={css(styles.openingHours)}>
      <IonSkeletonText animated style={{ width: '60%', height: 14 }} />
    </div>
    <div className={css(styles.infoLine)}>
      <IonSkeletonText animated style={{ width: '25%', height: 14, marginRight: 8 }} />
      <IonSkeletonText animated style={{ width: '15%', height: 12 }} />
    </div> */}
  </IonItem>
);



export default function OrderItem({ order, onClick, ...pass }) {
  const handleClick = () => onClick(order);

  return (
    <IonItem
      detail={false}
      button
      onClick={handleClick}
      {...pass}
    >
      <IonLabel>
        Objednávka #{order.id}
      </IonLabel>
    </IonItem>
  );
}