import React, { forwardRef } from "react";
import addMinutes from "date-fns/addMinutes";
import { IonDatetime } from "@ionic/react";
import { formatTime, getInRange } from "../library/opening-hours";

const todayFutureDate = ({ hr = 0, min = 0 } = {}) => {
  const date = new Date(); // now
  console.log({ hr, min });
  if (hr > date.getHours()) {
    date.setHours(hr);
  }

  if (hr === date.getHours() && min > date.getMinutes()) {
    date.setMinutes(min);
  } else if (typeof min === 'number') {
    date.setMinutes(min);
  }


  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

const timePickerOptions = (onSubmit, date) => {
  const makeTime = ({
    minute: { value: min = date.getMinutes() },
    hour: { value: hr = date.getHours() },
  }) => todayFutureDate({ hr, min }).toISOString();

  const handleAsap = () => {
    onSubmit(null);
  };
  const handleTime = (value) => {
    onSubmit(makeTime(value));
  };

  return {
    buttons: [
      { text: 'do 45min', handler: handleAsap },
      { text: 'Použít', handler: handleTime },
    ],
  };
};

HandoverTimeInput.forwardRef = forwardRef((props, ref) => {
  return <HandoverTimeInput forwardedRef={ref} {...props} />;
});

function calculateMinHandoverTime({ preparationTime = 45 }, range, now) {
  const { isOpen, isNonstop, startTime, endTime } = range;
  const ready = addMinutes(now, preparationTime);
  const readyTime = formatTime(ready);
  if (isNonstop || (isOpen && (readyTime < endTime)))
    return ready.toISOString();

  if (!startTime) return;
  const [startHours, startMinutes] = startTime.split(":");
  const open = new Date().setHours(startHours, startMinutes);
  const afterOpen = addMinutes(open, preparationTime);
  if (!isOpen && (readyTime < startTime))
    return afterOpen.toISOString();
}

function calculateMaxHandoverTime({ preparationTime = 45 }, range, now) {
  const { isNonstop, endTime } = range;
  if (isNonstop) {
    const midnight = new Date().setHours(23, 59);
    return midnight.toISOString();
  }
  if (endTime) {
    const ready = addMinutes(now, preparationTime);
    const readyTime = formatTime(ready);
    if (readyTime < endTime)
      return ready.toISOString();
  }
}

export default function HandoverTimeInput({
  value, onChange,
  dealer,
  forwardedRef,
  ...pass
}) {

  if (dealer.openingHours) {
    const now = new Date();
    const range = getInRange(dealer.openingHours, now);
    var min = calculateMinHandoverTime(dealer, range, now);
    var max = calculateMaxHandoverTime(dealer, range, now);
  }

  return (
    <IonDatetime
      role="dialog"
      ref={forwardedRef}
      pickerOptions={timePickerOptions(onChange, value)}
      displayFormat="H:mm"
      // displayTimezone="Z"
      minuteValues="00,15,30,45"
      value={value}
      min={min}
      max={max}
      {...pass}
    />
  );
}
