import React, { useMemo, useEffect } from "react";
import { IonModal, IonButton, isPlatform } from "@ionic/react";
import { StyleSheet, css } from "aphrodite";
import AddressInput from "../components/AddressInput";
import useDeviceLocation from "../hooks/useDeviceLocation";
import useGoogleMaps from "../hooks/useGoogleMaps";
import useAlertToast from "../hooks/useAlertToast";

const validateLocation = ({ address, placeId }) => (placeId && address); // && address.split(' ').length > 1;
const permissionsRequestDelay = 1200; // muhaha, fixed ios, I hope

export default function UserLocationAddressModal({
  open = false, onClose = () => { },
  userLocation = {}, updateUserLocation = () => { },
  submitButtonTitle = 'Použít adresu',
  ...pass
}) {
  const showAlert = useAlertToast({ duration: 4200 });

  const deviceLocation = useDeviceLocation();
  useEffect(() => {
    if (open) setTimeout(() => { deviceLocation.enable(); }, permissionsRequestDelay);
  }, [open]); // eslint-disable-line

  const google = useGoogleMaps();
  let googleMapEmptyDiv = null;

  const userLocationIsValid = useMemo(() => validateLocation(userLocation.form), [userLocation.form.address]); // eslint-disable-line

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!userLocationIsValid) {
      showAlert("Zadejte, prosím, přesnější adresu.");
      return;
    }

    const places = new google.maps.places.PlacesService(googleMapEmptyDiv);
    const { address, placeId } = userLocation.form;

    places.getDetails({
      fields: ["geometry.location"],
      placeId,
    }, (place, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        return;
      }
      const coordinates = [place.geometry.location.lat(), place.geometry.location.lng()];
      updateUserLocation({ coordinates, placeId, address });
      onClose();
    });
  };

  return (
    <IonModal
      isOpen={open}
      swipeToClose
      keyboardClose
      onDidDismiss={onClose}
      {...pass}
    >
      <div ref={(e) => { googleMapEmptyDiv = e; }} />
      <form onSubmit={handleSubmit}>
        <h3 className={css(styles.title)}>Podniky v okolí</h3>
        <div className={css(styles.address)}>
          <AddressInput
            label="Vaše adresa pro vyhledávání"
            value={userLocation.form.address}
            onChange={(address, placeId) => {
              updateUserLocation({ form: { address, placeId } });
            }}
            locationPosition={deviceLocation.position}
          />
        </div>
        <div className={css(styles.submit)}>
          <IonButton
            onClick={handleSubmit}
            expand="block"
            size={(isPlatform('desktop') || isPlatform('tablet')) ? 'large' : 'default'}
            disabled={!userLocationIsValid}
            type="submit"
          >
            {submitButtonTitle}
          </IonButton>
        </div>
      </form>
    </IonModal>
  );
}

const styles = StyleSheet.create({
  content: { display: 'flex', flexDirection: 'row' },
  title: { color: 'var(--ion-text-color)' },
  handover: { paddingBottom: 8 },
  label: { margin: '16px 0 8px' },
  submit: { marginTop: 102 },
});
