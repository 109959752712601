import React, { useState, useEffect } from "react";
import { StyleSheet, css } from "aphrodite";
import Autosuggest from 'react-autosuggest';
import useSuggestions from "../hooks/useSuggestions";
import useFeatherIcons from "../hooks/useFeatherIcons";

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => <div>{suggestion.description}</div>;


export default function AddressInput({
  value = '',
  onChange = () => { },
  locationPosition: position,
  placeholder = 'Objednej si z podniků ve svém okolí...',
  label,
  justStreet = true,
  onFocus,
}) {
  const [stopFetching, setStopFetching] = useState(false);
  const [address, setAddress] = useState(value);
  const [suggestions, setSuggestions] = useSuggestions({ address, position, stopFetching });

  const handleChange = (newValue) => {
    if (typeof newValue === "string") {
      setAddress(newValue);
      onChange(newValue, null);
    } else {
      const addr = newValue.description;
      setAddress(addr);
      onChange(addr, newValue.place_id);
    }
  };

  useFeatherIcons();

  // Gain place_id even if user does not touch suggestion dropdown but just typed exact addres
  // TODO: remove diacritic
  const exactAddressSuggestion = () => {
    if (!onChange) return console.warn('Callback `onChange` has to be defined');

    const { description, place_id } = suggestions[0] || {};

    if (place_id && address === description) { // "Hornoměcholupská, Praha 15-Horní Měcholupy, Česko" => "Hornoměcholupská"
      handleChange({ description, place_id });;
    }
  };
  useEffect(exactAddressSuggestion, [suggestions, suggestions.length]); // eslint-disable-line

  return (
    <div className={css(styles.container)}>
      <p className={css(styles.label)}><label id="address-input__label">{label}</label></p>
      <div className={css(styles.background)}>
        <Autosuggest
          alwaysRenderSuggestions={suggestions.length > 1}
          suggestions={suggestions}
          getSuggestionValue={(s) => s}
          getSectionItems={x => x}
          renderSectionTitle={x => x}
          onSuggestionSelected={() => {
            setSuggestions([]);
          }}
          onSuggestionsClearRequested={() => {
            setSuggestions([]);
          }}
          onSuggestionsFetchRequested={({ reason }) => setStopFetching(reason !== 'input-changed')}
          renderSuggestion={renderSuggestion}
          highlightFirstSuggestion
          inputProps={{
            placeholder,
            // Remove ", Praha 15-Horní Měcholupy, Česko", the Mister Sherlock's information
            // its pain to modify an house/street number on Mobile devices
            value: justStreet ? address.split(',')[0] : address,
            className: css(styles.input),
            onChange: (event, { newValue }) => {
              handleChange(newValue);
            },
            onFocus: () => {
              // Hack all buttons to not close (blur) keyboard on press
              [...document.querySelectorAll('ion-button')].forEach((button) => {
                button.onmousedown = (event) => {
                  event.preventDefault();
                };
              });

              setImmediate(onFocus);
            },
            onBlur: () => {
              // Hide suggestion on keyboard close
              setSuggestions([]);
            },
            //
            "aria-labelledby": "address-input__label",
            "aria-activedescendant": true,
          }}
        />
        <button
          type="reset"
          // Do not hide keyboard on value clear
          onMouseDown={(event) => { event.preventDefault(); }}
          onClick={(event) => {
            handleChange('');
          }}
          className={css(styles.clearButton)}
          style={{ opacity: address ? 1 : 0 }}
          aria-hidden="true" // Voiceover can do that natively
        // aria-label="Smazat textové pole"
        >
          <i data-feather="x-circle" className="orderIcon fix-iphone-clear-icon-cut-off" />
        </button>

        {justStreet && <p className={css(styles.context)}>{address.split(',')[1]}</p>}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  background: {
    position: 'relative',
    background: 'var(--ion-color-light)',
    borderRadius: 4,
    border: '1px solid var(--ion-color-light-shade)',
  },
  input: {
    background: 'transparent',
    border: '0 none',
    padding: 8,
    '@media (min-width: 375px)': { padding: 16, paddingRight: 46 },
    width: '100%',
    color: 'var(--ion-color-dark)',
    paddingRight: 32,
  },
  clearButton: {
    background: 'transparent',
    color: 'var(--ion-color-dark)',
    position: 'absolute',
    right: 24,
    top: 0,
    bottom: 0,
    width: 16,
  },
  context: {
    position: 'absolute',
    bottom: -42,
    right: 0,
    left: 0,
    textAlign: 'center',
    fontSize: 14,
    color: 'var(--ion-color-medium)',
  },
});
