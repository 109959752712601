/**
 * Core UI component for Dealers list/grid.
 *
 */

import React, { useMemo } from "react";
import { IonNote, IonSkeletonText } from "@ionic/react";
import { css, StyleSheet } from "aphrodite";
import OpeningHours from "./OpeningHours";
import DeliveryPriceSticker from "./DeliveryPriceSticker";
import ThumbnailCard from "./ThumbnailCard";
import useRemoteConfig from "../hooks/useRemoteConfig";


DealerCard.Placeholder = () => (
  <ThumbnailCard.Placeholder>
    <IonSkeletonText animated style={{ width: '40%', height: 16 }} />
    <div className={css(styles.openingHours)}>
      <IonSkeletonText animated style={{ width: '60%', height: 14 }} />
    </div>
    <div className={css(styles.infoLine)}>
      <IonSkeletonText animated style={{ width: '25%', height: 14, marginRight: 8 }} />
      <IonSkeletonText animated style={{ width: '15%', height: 12 }} />
    </div>
  </ThumbnailCard.Placeholder>
);




export default function DealerCard({ dealer, manager: [userIsDealer, userEmail] }) {
  const { title, logo, logoColor, openingHours } = dealer;
  const remoteConfig = useRemoteConfig();

  const isUserDealerManager = useMemo(() => (
    userIsDealer && (dealer.managers || []).includes(userEmail)
  ), [userIsDealer, dealer.managers, userEmail]);

  return (
    <ThumbnailCard
      thumbnailImage={logo}
      thumbnailColor={logoColor}
      routerLink={isUserDealerManager ? `/objednavky/${dealer.id}` : `/podnik/${dealer.id}`}
      routerState={{ dealer }}
      className={css(styles.container)}
    >
      <h2 className={css(styles.title)}>{title}{isUserDealerManager && '*'}</h2>
      <p className={css(styles.content)}>{dealer.address}</p>

      <div className={css(styles.openingHours)}>
        <OpeningHours hours={openingHours} />
      </div>

      <div className={css(styles.infoLine)}>
        {remoteConfig.handover?.includes('delivery') && (
          <DeliveryPriceSticker
            dealer={dealer}
            todo={console.warn('Not Delivery not yet implemented')}
          />
        )}
        <IonNote color="dark">{`${dealer.distance.toFixed(2)} km`}</IonNote>
      </div>
    </ThumbnailCard>
  );
}


const styles = StyleSheet.create({
  container: { minHeight: 112 },
  title: { lineHeight: 1.4, fontSize: 18, margin: 0 },
  content: { margin: '0 0 4px' },
  openingHours: { marginBottom: 12 },
  infoLine: {
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
});
