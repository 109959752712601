import React, { useEffect } from "react";
import { IonChip, IonLabel } from "@ionic/react";
import { css, StyleSheet } from "aphrodite";
import useFeatherIcons from "../hooks/useFeatherIcons";

export default function DeliveryPriceSticker({
  dealer,
  deliveryAddress,
}) {
  useEffect(() => {
    console.warn(
      'DeliveryPriceSticker not yet implemented.',
      { dealer, deliveryAddress },
    );
  }, [dealer, deliveryAddress]);

  useFeatherIcons();

  return (
    <IonChip className={css(styles.chip)} color="medium">
      <i data-feather="truck" className="deliveryIcon"/>
      <IonLabel color="dark">39 Kč</IonLabel>
    </IonChip>
  );
}

const styles = StyleSheet.create({
  chip: {
    margin: '0 8px 0 0',
    height: 24,
    borderRadius: 4,
  },
});
