import React, { useState } from "react";
import {
  IonFooter, IonToolbar, IonButton,
  useIonViewDidEnter, useIonViewWillLeave, isPlatform,
} from "@ionic/react";
import { StyleSheet, css } from "aphrodite";

export default function FooterButton({
  visible = true, title, onClick = () => { }, disabled, secondary,
  ...pass
}) {
  const [still, setStill] = useState(false);

  useIonViewDidEnter(() => { setStill(true); });
  useIonViewWillLeave(() => { setStill(false); });

  return (
    <div className={css(styles.footer, still && visible && styles.visibleContainer)}>
      <IonFooter>
        <IonToolbar>
          <IonButton
            size={(isPlatform('desktop') || isPlatform('tablet')) ? 'large' : 'default'}
            color={secondary ? 'secondary' : 'primary'}
            onClick={onClick}
            expand="block"
            className={css(styles.button)}
            disabled={disabled}
            {...pass}
          >
            {title}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: 72,
    '@media (min-width: 480px) and (min-height: 386px)': {
      minHeight: 102,
    },
    background: 'var(--ion-background-color)',
  },
  footer: {
    zIndex: 1,
    transform: 'translate(0, 150%)',
    transition: '.4s ease',
    padding: 14,
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
  },
  visibleContainer: {
    transform: 'translate(0, 0)',
    '@media (min-width: 480px) and (min-height: 386px)': {
      transform: 'translate(0, -10%)',
    },
  },
  button: {
    maxWidth: 480, // it's for desktop
    margin: '0 auto',
  },
});
