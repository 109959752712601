import { useState, useEffect } from "react";

/**
 * https://usehooks.com/useWindowSize/
 */
export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

const isClient = typeof window === "object";
const getWindowSize = () => {
  if (!isClient) return { width: undefined, height: undefined };
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};
