import React, { useRef } from "react";
import { ScreenProps } from '../types';
import {
  IonButton,
  IonContent,
  // IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import { StyleSheet, css } from "aphrodite";
import ValueItem from "../components/ValueItem";
import TelephoneInput from "../components/TelephoneInput";
import FooterButton from "../components/FooterButton";
import CallToAction from "../components/CallToAction";
import UserLocationAddressModal from "../modals/UserLocationAddress";
import CardValueInput from "../components/CardValueInput";
import DealerSignInModal from "../modals/DealerSignIn";
import DealerSignUpModal from "../modals/DealerSignUp";
import useFirestoreForm from '../hooks/useFirestoreForm';
import OrdersList from "../components/OrdersList";
import useAlertToast from "../hooks/useAlertToast";

export default function CustomerProfileScreen({
  history, auth,
  userLocation, updateUserLocation,
  userContacts, updateUserContacts,
  creditCard, updateCreditCard,
  customerProfileScreen, updateCustomerProfileScreen,
  dealerSignForm, updateDealerSignForm,
  dealerPreferences, updateDealerPreferences,
}: ScreenProps) {
  const {
    readyToLoad,
    showUserLocationModal,
    showDealerSignInModal,
    showDealerSignUpModal,
  } = customerProfileScreen;

  useIonViewWillEnter(() => {
    updateCustomerProfileScreen({ readyToLoad: true });
  });
  useIonViewDidEnter(() => {
    updateCustomerProfileScreen({ readyToLoad: true });
  });

  const showAlert = useAlertToast();

  // Collect user opinions
  const submitDealerRequestForm = useFirestoreForm('zadosti-obchodniku', ['name', 'address', 'email', 'website', 'telephone', 'userAgreedAllRequiredConditions']);
  const handleDealerSignUpSubmit = async ({ email }) => {
    if (email.endsWith('@topmonks.com')) await auth.signUp(dealerSignForm);
    await submitDealerRequestForm(dealerSignForm);
  };


  // Switch do customer mode or pop the SignUp modal
  const handleDealerEntry = () => {
    if (auth.user.isAnonymous) {
      updateCustomerProfileScreen({ showDealerSignUpModal: true });
    } else {
      updateDealerPreferences({ customerMode: false });
      history.replace('/nastaveni');
    }
  };

  const handleDealerSignInSubmit = async (form) => {
    await auth.signIn(form);
    showAlert('Vítejte obchodníku!', { color: 'success' });
    history.replace('/nastaveni');
  };

  // Whoot, what a sexy modal backdrop on the iOS 🤩
  const pageRef = useRef();

  // appearance
  const isFooterButtonVisible = readyToLoad
    && !showUserLocationModal
    && !showDealerSignInModal
    && !showDealerSignUpModal;


  return (
    <IonPage className="force-tablet-like-view" ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Jsem Zákazník</IonTitle>

          <IonButtons slot="end">
            <IonButton
              color="secondary"
              onClick={handleDealerEntry}
            >
              Podnik
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={css(styles.content)}>
        <div className={css(styles.preferences)}>
          <ValueItem title="Kdyby něco, zavoláme." allowClickOnContainer={false} buttonTitle={null}>
            <TelephoneInput
              value={userContacts.telephone}
              onChange={(telephone) => {
                updateUserContacts({ telephone });
              }}
            />
          </ValueItem>
          <ValueItem
            title="Vaše adresa pro vyhledávání v okolí"
            value={userLocation.address}
            onChangeRequest={() => {
              updateCustomerProfileScreen({ showUserLocationModal: true });
            }}
          />

          {false && ( // TODO allow to forget save card if any
            <ValueItem
              allowClickOnContainer={false}
              title="Platební karta"
              buttonTitle={null}
            >
              <CardValueInput card={creditCard} updateCard={updateCreditCard} />
            </ValueItem>
          )}
        </div>

        {readyToLoad && (
          <OrdersList
            className={css(styles.orders)}
            customerId={auth.user?.uid}
            onOrderClick={order => {
              history.push(`/objednavka/${order.id}`, { order });
            }}
          >
            <CallToAction type="customer-empty-orders" className={css(styles.ordersEmpty)} />
          </OrdersList>
        )}

      </IonContent>

      <FooterButton
        visible={isFooterButtonVisible}
        title="Zobrazit podniky v okolí"
        routerLink="/"
        routerDirection="back"
      />

      <UserLocationAddressModal
        open={showUserLocationModal}
        onClose={() => { updateCustomerProfileScreen({ showUserLocationModal: false }); }}
        {...{ userLocation, updateUserLocation }}
        justAddress
        presentingElement={pageRef.current}
      />
      <DealerSignInModal
        open={showDealerSignInModal}
        onClose={() => {
          updateCustomerProfileScreen({ showDealerSignInModal: false });
        }}
        onUpRequest={() => {
          updateCustomerProfileScreen({
            showDealerSignUpModal: true,
            showDealerSignInModal: false,
          });
        }}
        form={dealerSignForm}
        onChange={updateDealerSignForm}
        onSubmit={handleDealerSignInSubmit}
        presentingElement={pageRef.current}
      />
      <DealerSignUpModal
        open={showDealerSignUpModal}
        onClose={() => {
          updateCustomerProfileScreen({ showDealerSignUpModal: false });
        }}
        onInRequest={() => {
          updateCustomerProfileScreen({
            showDealerSignUpModal: false,
            showDealerSignInModal: true,
          });
        }}
        form={dealerSignForm}
        onChange={updateDealerSignForm}
        onSubmit={handleDealerSignUpSubmit}
        presentingElement={pageRef.current}
      />

    </IonPage>
  );
}

const styles = StyleSheet.create({
  content: {},
  preferences: {
    display: 'flex',
    flexDirection: 'column',
    // height: 'calc(100% - 102px)', // the footer submit button space
  },
  ordersEmpty: { flex: 1 },
  orders: {
    background: 'var(--ion-item-background)',
  },
  ordersTitle: {
    padding: 16,
  },
});
