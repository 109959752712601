import { OrderRequest } from '../types';

export const isOrderDoneForCustomer = ({ state: orderState }: OrderRequest) => (
  [null, 'PENDING', 'PAYMENT_FAILURE'].includes(orderState) === false
);


export const isOrderPending = ({ state: orderState }: OrderRequest) => (
  'PENDING' === orderState
);

