import React, { useMemo } from "react";
import { StyleSheet, css } from "aphrodite";


const illustrations = {
  delivery: require('../assets/illustrations/delivery.svg'),
  'delivery-dark': require('../assets/illustrations/delivery-dark.svg'),
  pickup: require('../assets/illustrations/pickup.svg'),
  'pickup-dark': require('../assets/illustrations/pickup-dark.svg'),
  error: require('../assets/illustrations/error.svg'),
  'error-dark': require('../assets/illustrations/error-dark.svg'),
  offline: require('../assets/illustrations/offline.svg'),
  'offline-dark': require('../assets/illustrations/offline-dark.svg'),
  'orders-empty': require('../assets/illustrations/orders-empty.svg'),
  'orders-empty-dark': require('../assets/illustrations/orders-empty-dark.svg'),
  'dealers-empty': require('../assets/illustrations/dealers-empty.svg'),
  'dealers-empty-dark': require('../assets/illustrations/dealers-empty-dark.svg'),
  home: require('../assets/illustrations/home.svg'),
  'home-dark': require('../assets/illustrations/home-dark.svg'),
};

type IllustrationType =
  | 'delivery'
  | 'delivery-dark'
  | 'pickup'
  | 'pickup-dark'
  | 'error'
  | 'error-dark'
  | 'offline'
  | 'offline-dark'
  | 'orders-empty'
  | 'orders-empty-dark'
  | 'dealers-empty'
  | 'dealers-empty-dark'
  | 'home'
  | 'home-dark'

const illustrationSourceByType = (type) => illustrations[type];

export default function Illustration({ type, className, ...pass }: { type: IllustrationType }) {
  const source = useMemo(() => illustrationSourceByType(type), [type]);

  return (
    <div className={`${css(styles.container)} ${className}`} {...pass}>
      <img className={css(styles.image)} src={source} alt={`Illustration of type ${type}`} />
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 'auto',
    textAlign: 'center',
    padding: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '60vw',
    '@media (min-width: 365px)': { maxWidth: '60vw' },
    '@media (min-width: 730px)': { maxWidth: '80vw' },
    alignSelf: 'flex-end',
  },
});
