/* eslint-disable jsx-a11y/alt-text */
/**
 * Used for Menu Items and Dealer Cards
 *
 * This is how it looks:
 * __________________________________
 * |                                |
 * | 432437   hwefbebvkhbsd         |
 * | 324336   dg3irehgurgbefuygr    |
 * | 235551   wdbjdgcejhgdbhvvhxc   |
 * |________________________________|
 */
import React from "react";
import { css, StyleSheet } from "aphrodite";
import { IonRouterLink, IonSkeletonText } from "@ionic/react";
import { useHistory } from "react-router";
import purifyState from '../library/purify-state';

const itemBackgroundImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOUkpb7DgAB9gFMK4ZoVAAAAABJRU5ErkJggg==";

ThumbnailCard.Placeholder = ({ children }) => (
  <div className={css(styles.container)}>
    <div className={css(styles.thumbnail)}>
      <IonSkeletonText animated className={css(styles.image)} />
    </div>
    <div className={css(styles.content)}>{children}</div>
  </div>
);

export default function ThumbnailCard({
  thumbnailImage,
  thumbnailSize,
  thumbnailColor,
  highlight = false,
  children: content,
  routerState,
  routerLink,
  ...pass
}) {
  const history = useHistory();
  const handleClick = () => {
    const state = purifyState(routerState);
    history.push(routerLink, state);
  };

  return (
    <IonRouterLink color="none" onClick={handleClick} {...pass}>
      <div className={css(styles.container, highlight && styles.highlight)}>
        {!!thumbnailImage && (
          <div
            className={css(styles.thumbnail)}
            style={{ backgroundColor: thumbnailColor }}
            aria-hidden // Tomas does not need to hear this at all
          >
            <img
              src={thumbnailImage || itemBackgroundImage}
              className={css(styles.image)}
            />
          </div>
        )}
        <div className={css(styles.content)}>
          {content}
        </div>
      </div>
    </IonRouterLink>
  );
}

const styles = StyleSheet.create({
  container: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    background: 'var(--ion-item-background)',
    lineHeight: 0,
    marginBottom: 8,
    '@media (min-width: 720px)': {
      margin: 8,
      borderRadius: 4,
    },
    ":active": {
      background: 'var(--ion-item-background-activated)',
    },
    border: '2px solid transparent',
  },
  highlight: {
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
    borderColor: 'var(--ion-color-secondary)',
    borderWidth: 2,
    background: 'var(--ion-item-background-activated)',
    
  },
  thumbnail: {
    width: 72,
    height: 72,
    '@media (min-width: 375px)': {
      width: 84,
      height: 84,
    },
    '@media (min-width: 780px)': {
      width: 98,
      height: 98,
    },
    lineHeight: 0, // hacky fix
    margin: 16, // margin bcs borderRadius
    marginRight: 0, // to be closer to content that has own padding
    borderRadius: 12,
  },
  image: {
    objectFit: "contain",
    borderRadius: 12, // for sure
    width: '90%',
    height: '90%',
    margin: '5%',
  },
  content: {
    flex: 1,
    lineHeight: 'initial',
    padding: 16,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    // debug:
    // background: 'gray',
  },
});
