import React from "react";
import { StyleSheet, css } from "aphrodite";
import {
  IonModal,
  IonButton,
  IonHeader,
} from "@ionic/react";
import ModalProgressBar from "../components/ModalProgressBar";
import useFirestore from "../hooks/useFirestore";
import useAlertToast from "../hooks/useAlertToast";
import HumanOrderState from "../components/HumanOrderState";


export default function DealerOrderModal({
  open = false, onClose = () => { },
  order: orderPreview = {},
  ...pass
}) {

  // .. loads
  const [
    order = orderPreview,
    loadingOrder = true,
    errorOrder,
    orderReference,
  ] = useFirestore(
    db => db.doc(`orders/${orderPreview.id}`),
    { skip: !orderPreview?.id, realtime: true },
  );

  // .. hundlers
  // const disabledSubmitButton = dealerName.length < 2 || submiting;
  const handleConfirm = (confirmed) => () => {
    if (!confirmed && !window.confirm('Objednávka je již zaplacená, opravdu ji chcete stornovat?')) return;
    orderReference
      .update({ state: confirmed ? 'ACCEPTED' : 'REJECTED' })
      .then(onClose)
      .catch(showAlert);
  };

  // .. appearance
  const error = errorOrder || null;
  const showLoading = loadingOrder || false;

  const showAlert = useAlertToast({ message: error });

  return (
    <IonModal isOpen={open} swipeToClose onDidDismiss={onClose} keyboardClose {...pass}>
      <IonHeader className={css(styles.header)}>
        <ModalProgressBar submiting={showLoading} error={error} />
        <h3 className={css(styles.title)}>Objednávka #{order?.id}</h3>
        <HumanOrderState state={order?.state} backoffice />

      </IonHeader>


      <div className={css(styles.buttons)}>
        <IonButton onClick={handleConfirm(false)} className={css(styles.button)} expand="block" color="danger">Storno</IonButton>
        <IonButton onClick={handleConfirm(true)} className={css(styles.button)} expand="block" color="success">Přijmout</IonButton>
      </div>
    </IonModal>
  );
}

const styles = StyleSheet.create({
  // content: { minHeight: 210 },
  title: { color: 'var(--ion-text-color)' },
  header: { paddingBottom: 8 },
  label: { margin: '16px 0 8px' },
  buttons: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  button: { flex: 1, margin: 4 },
});
