import React from 'react';
// import { IonItem, IonLabel, IonTitle } from "@ionic/react";
import { StyleSheet, css } from "aphrodite";


export default function DailyTurnover({ className }) {

  return (
    <div className={`${css(styles.container)} ${className}`}>
      <h2 className={css(styles.title)}>Suma sumárum</h2>

    </div>
  );
}


const styles = StyleSheet.create({
  container: {
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center',
  },

  title: { margin: '0 0 6px', fontSize: 21, '@media (min-width: 420px)': { fontSize: 24 } },

});
