/**
 * Core UI component for Menu list/grid.
 *
 */

import React from "react";
import { StyleSheet, css } from "aphrodite";
import { IonSkeletonText, isPlatform } from '@ionic/react';
import ThumbnailCard from "./ThumbnailCard";
import AmountButtons from "./AmountButtons";
import { price as formatPrice } from "../library/format";

export const MAX_AMOUNT_LIMIT = 20; // Twenty of Hawaii pizza? Why not, but no more!


MenuItem.Placeholder = () => (
  <ThumbnailCard.Placeholder>
    <IonSkeletonText animated style={{ width: '40%', height: 16 }} />
    <div className={css(styles.openingHours)}>
      <IonSkeletonText animated style={{ width: '60%', height: 14 }} />
    </div>
    <div className={css(styles.infoLine)}>
      <IonSkeletonText animated style={{ width: '25%', height: 14, marginRight: 8 }} />
      <IonSkeletonText animated style={{ width: '15%', height: 12 }} />
    </div>
  </ThumbnailCard.Placeholder>
);


export default function MenuItem({
  item: {
    // id,
    // foreignId,
    title,
    description,
    price,
    quantity, // like 300dg
    thumbnail,
    additives = [],
    image,
  },
  amount = 0,
  onAmountChange,
}: {
  amount: number,
  onAmountChange: (amount: number) => void
}) {
  // const totalPrice = useMemo(() => amount * parseInt(price), [amount, price]);

  const handleAmountChange = (changedAmount) => {
    if (changedAmount !== amount) {
      onAmountChange(changedAmount);
    }
  };
  const descriptionSuffix = additives.filter(Boolean).length > 0 ? `(${additives.filter(Boolean).join(', ')})` : '';

  return (
    <ThumbnailCard
      thumbnailImage={thumbnail || image}
      className={css(styles.container)}
      highlight={amount > 0}
      onClick={(event) => {
        if (!event.defaultPrevented) {
          handleAmountChange(amount ? 0 : 1);
        }
      }}
    >
      <div className={css(styles.header)}>
        <h2 className={css(styles.title)}>{title}</h2>
        <div className="ion-text-right">
          <div className={css(styles.price)}>{formatPrice(price)}</div>
        </div>
      </div>


      <div className={css(styles.content)}>
        {quantity && <div className={css(styles.quantity)}>{quantity}</div>}
        {(!description || description.endsWith('.')) ? `${description} ${descriptionSuffix}` : `${description}. ${descriptionSuffix}`}
      </div>
      {(amount > 0 || isPlatform('desktop')) && (
        <AmountButtons
          className={css(styles.buttons)}
          label="Množství:"
          value={amount}
          onChange={handleAmountChange}
          max={MAX_AMOUNT_LIMIT}
          productTitle={title}
        />
      )}
    </ThumbnailCard>
  );
}

const styles = StyleSheet.create({
  container: { position: 'relative', display: 'flex', flexDirection: 'column' },
  header: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  title: {
    flex: 1,
    margin: 0,
    lineHeight: '24px',
    fontSize: 18,
    marginRight: 8,
  },
  price: { lineHeight: '18px', fontSize: 18 },
  content: {
    flex: 1,
    fontSize: 12,
    lineHeight: '16px',
  },
  quantity: { color: 'var(--ion-color-medium)', fontSize: 12 },
  buttons: { marginTop: 8 },
});
